// brand colors where the negative color should be gray

const colorsSimilarToRed = ["orange", "pink", "purple", "red", "amber"];

// return colors object that could vary depending on the principal/brand color

const buildSiteColors = (brandColor = null) => {
  return {
    brand: brandColor || "blue",
    background: "gray",
    card: "white",
    text: "gray",
    titles: "gray",
    primary: "gray",
    buttons: "pink",
    negative: colorsSimilarToRed.includes(brandColor) ? "orange" : "red",
  };
};

export default buildSiteColors;
