import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "@material-tailwind/react";
import c from "../hooks/Capitalize";

const UnwantedIngridient = ({ ingr, handleRemovable, removed }) => {
  const colors = useSelector((state) => state.rest.rest.siteColors);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (removed.indexOf(ingr) === -1) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, [removed]);

  return (
    <div className="">
      <div className="flex justify-between items-center -mb-3">
        <p className="text-sm">{c(ingr.name)}</p>
        <Checkbox
          color={colors.negative}
          size="sm"
          onChange={() => handleRemovable(ingr)}
          defaultChecked={checked}
          className="bg-white w-4 h-4"
        />
      </div>
    </div>
  );
};

export default UnwantedIngridient;
