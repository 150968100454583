import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardFooter,
  Typography,
  Button,
} from "@material-tailwind/react";
import useIsInViewport from "../hooks/isInViewPort";
import { clear } from "../store/cartSlice";
import pickRandom from "../hooks/randomInt";
import RenderItems from "./RenderItems";
import groupItems from "../hooks/GroupItems";
import ColorPicker from "../hooks/ColorPicker";
import Price from "../hooks/Price";

const Cart = ({ ref2 }) => {
  const { rest } = useSelector((state) => state.rest);
  const colors = rest.siteColors;
  const { subTotal, items } = useSelector((state) => state.cart);
  const { empty } = useSelector((state) => state.cart);
  const [cartItems, setCartItems] = useState(null);
  const dispatch = useDispatch();

  const isInViewport2 = useIsInViewport(ref2);

  useEffect(() => {
    const previewer = document.querySelector(".preview-wrapper");

    if (isInViewport2 || empty) {
      previewer.classList.remove("float");
    } else if (!isInViewport2 || !empty) {
      previewer.classList.add("float");
    }
  }, [isInViewport2, empty]);

  useEffect(() => {
    setCartItems(groupItems(items));
  }, [items]);

  return (
    <Card
      className={`checkout-cart w-full ${ColorPicker("background", colors.card)} ${ColorPicker("text", colors.text)}`}
    >
      <CardBody className="text-left flex flex-col justify-start">
        <span className="font-bold text-xl">TU PEDIDO:</span>
        <span ref={ref2} />
        {!empty && (
          <>
            <hr className="border-top-2 border-dashed border-gray-400 mt-2 pb-2" />
            <RenderItems items={cartItems} withEmojies />
          </>
        )}
        {empty && (
          <Typography variant="small" className="flex flex-col">
            <span>Aún no has añadido nada a tu pedido...</span>
          </Typography>
        )}
      </CardBody>
      {!empty && (
        <CardFooter className="flex flex-col justify-center gap-2 -mt-10">
          <p className="text-right text-lg font-bold">
            <span>Sub-total: ${Price(subTotal)}</span>
          </p>
          <Button
            variant="gradient"
            color={colors.brand}
            size="lg"
            className="relative"
            data-bs-toggle="modal"
            data-bs-target="#checkoutModal"
          >
            <span>Continuar con el pedido</span>
            <span className="absolute right-4">🛵</span>
          </Button>
          <Button
            variant="outlined"
            color={colors.negative}
            size="sm"
            onClick={() => dispatch(clear())}
            className="relative"
          >
            <span>Borrar pedido</span>
            <span className="absolute right-4 ">🗑</span>
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};

export default Cart;
