import "./App.css";
import React, { useRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDataFromLocalStorage } from "./app/store/clientSlice";
import { useParams, Link } from "react-router-dom";
import { fetchRest, setMenuVersion, setSiteColors } from "./app/store/restSlice";
import Navbar from "./app/components/Navbar";
import BusinessCard from "./app/components/BusinessCard";
import Menu from "./app/components/Menu";
import Previewer from "./app/components/Previewer";
import Cart from "./app/components/Cart";
import Checkout from "./app/components/Checkout";
import ColorPicker from "./app/hooks/ColorPicker";
import buildSiteColors from "./app/hooks/buildSiteColors";
import updatePageTitle from "./app/hooks/updatePageTitle";
import Loader from "./app/components/Loader";
// import ReactGA from "react-ga";

function App({ menuVersion }) {
  const { slug } = useParams();
  const [colors, setColors] = useState({ ...buildSiteColors(null), default: true });
  const [hasActiveCategories, setHasActiveCategories] = useState(false);
  const restSlice = useSelector((state) => state.rest);
  const ref2 = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    // fetches the restaurant info from the api
    if (slug) {
      dispatch(fetchRest(slug));
    }
  }, []);

  useEffect(() => {
    if (restSlice.status === "loaded" && restSlice.rest?.site && restSlice.rest.site.active) {
      updatePageTitle(`${restSlice.rest.name} - PidamosGO`);
      const activeCategories = restSlice.rest.site.menu.categories.filter((category) => {
        const items = category.items.filter((item) => item.active);
        return category.active && items.length > 0;
      });
      setHasActiveCategories(!menuVersion && activeCategories.length > 0);
      const restColors = buildSiteColors(restSlice.rest.site.color_buttons);
      setColors({ ...restColors, default: false });
    }

    if (menuVersion) {
      dispatch(setMenuVersion());
    }
  }, [restSlice]);

  useEffect(() => {
    dispatch(getDataFromLocalStorage());
  });

  // update rest slice site colors only if they're not set yet
  useEffect(() => {
    if (colors && !colors.default && Object.keys(colors).length > 0 && !restSlice.rest.siteColors) {
      dispatch(setSiteColors(colors));
    }
  }, [colors]);

  return (
    <div
      className={`app-wraper p-2 min-h-screen max-w-[400px] ${ColorPicker(
        "background",
        colors.background,
        300
      )} font-roboto ${ColorPicker("text", colors.text)}`}
    >
      {restSlice.status === "loaded" && restSlice.rest.siteColors && (
        <>
          <Navbar rest={restSlice.rest} />
          <BusinessCard rest={restSlice.rest} />
          <Menu />
          {hasActiveCategories && (
            <>
              <Cart ref2={ref2} />
              <div className="preview-wrapper flex flex-col justify-center items-center fixed bottom-0 left-0 p-2.5  ">
                <Previewer ref2={ref2} />
              </div>
              <Checkout ref2={ref2} />
            </>
          )}
        </>
      )}
      {restSlice.status === "unavailable" && (
        <div className="h-screen w-full flex flex-col justify-center items-center bg-white">
          <p className="text-4xl">🚛 🚜 👷</p>
          <p>La página de {restSlice.rest.name || "este negocio"} no está activa</p>
          <Link to="/">
            <button className="text-blue-700">Volver a PidamosGO</button>
          </Link>
        </div>
      )}
      {restSlice.status === "not_found" && (
        <div className="h-screen w-full flex flex-col justify-center items-center bg-white">
          <p className="text-4xl">🤷‍♂️</p>
          <p>Página no encontrada...</p>
          <Link to="/">
            <button className="text-blue-700">Volver a PidamosGo</button>
          </Link>
        </div>
      )}
      {restSlice.status === "loading" && <Loader />}
    </div>
  );
}

export default App;
