const genderize = (item, maleWord, femaleWord, number) => {
  if (item.gender === "male") {
    return maleWord;
  } else if (item.gender === "female") {
    return femaleWord;
  } else if (item.gender === "mix") {
    if (number === "s") {
      return maleWord;
    } else {
      return femaleWord;
    }
  }
};

export default genderize;
