import { useState } from "react";
import { Textarea, Button } from "@material-tailwind/react";
import { useDispatch, useSelector } from "react-redux";
import { addNote } from "../store/cartSlice";
import ColorPicker from "../hooks/ColorPicker";

const NotesUI = ({ active, item, setAddedNote, handleNoteRemoval }) => {
  const { rest } = useSelector((state) => state.rest);
  const colors = rest.siteColors;
  const [text, setText] = useState("");
  const [saved, setSaved] = useState(false);
  const dispatch = useDispatch();

  const handleNoteAddition = () => {
    const note = {
      item: item.name,
      icon: item.icon,
      category: item.category,
      note: text,
    };
    dispatch(addNote(note));
    setAddedNote(note);
  };

  const handleText = (e) => {
    const newText = e.target.value;
    setText(newText);
  };

  return (
    <div className="text-xs mt-2">
      {active ? (
        <div>
          <Textarea
            label="Instrucciones para este producto"
            className="mb-2 h-2"
            disabled={saved}
            onChange={handleText}
            value={text}
            color={colors.brand}
          />
          <div className="flex justify-between items-center">
            {saved ? (
              <>
                <p className={`${ColorPicker("text", colors.brand)}`}>Nota añadida ✅</p>
                <Button
                  className="text-xs py-2 px-3 border-dashed"
                  variant="outlined"
                  onClick={() => {
                    handleNoteRemoval();
                    setSaved(!saved);
                  }}
                  color={colors.brand}
                >
                  Editar Nota
                </Button>
              </>
            ) : (
              <div className="flex justify-between items-center w-full">
                <p className={`${ColorPicker("text", colors.negative, 400)}`}>
                  {text === "" ? "La nota está vacía" : "Guarda la nota"} ⚠️
                </p>
                <div className="flex gap-2">
                  <Button
                    className="text-xs py-2 px-3 border-dashed"
                    variant={"outlined"}
                    onClick={() => setText("")}
                    disabled={text === ""}
                    color={text === "" ? "gray" : colors.negative}
                  >
                    Borrar
                  </Button>
                  <Button
                    className="text-xs py-2 px-3"
                    variant="filled"
                    onClick={() => {
                      handleNoteAddition();
                      setSaved(!saved);
                    }}
                    disabled={text === ""}
                    color={text === "" ? "gray" : colors.brand}
                  >
                    Guardar
                  </Button>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className="w-full flex justify-end "></div>
      )}
    </div>
  );
};

export default NotesUI;
