import { useSelector } from "react-redux";
import { Card, CardHeader } from "@material-tailwind/react";
import Price from "../hooks/Price";

import Icon from "./icons/Icon";

export default function Previewer({ ref2 }) {
  const { subTotal } = useSelector((state) => state.cart);

  const executeScroll = () => ref2.current.scrollIntoView();

  return (
    <Card id={"previewer"} className="w-full max-w-[400px] h-28 cursor-pointer z-50" onClick={() => executeScroll()}>
      <CardHeader floated={false} className="py-1 px-5 m-3 shadow-md">
        <div className="flex justify-between	items-center">
          <h4 className="mb-2 font-bold">Sub-total: ${Price(subTotal)}</h4>
          <Icon iconName="arrow-up" className="font-bold text-lg w-5 h-5" onClick={() => executeScroll()} />
        </div>
      </CardHeader>
    </Card>
  );
}
