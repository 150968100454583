import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-tailwind/react";
import g from "../hooks/Genderize";
import RenderItems from "./RenderItems";
import StepPicker from "./StepPicker";
import ColorPicker from "../hooks/ColorPicker";

const BuildItem = ({ item, i, itemsPreview, setItemsPreview, setBuiltTotal }) => {
  const colors = useSelector((state) => state.rest.rest.siteColors);
  const nameAndNumber = `${item.name} Nro ${i + 1}`;
  // const [added, setAdded] = useState({});
  const [price, setPrice] = useState(item.price);
  const [cleared, setCleared] = useState(true);
  const [readyToAdd, setReadyToAdd] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [currentItemTemplate, setCurrentItemTemplate] = useState(null); // create a template to restore if needed

  // const fixedPrice = item.price !== 0;

  // Push a placeholder/initial state for the steps to pick
  useEffect(() => {
    // if cleared build clean version of current Item
    if (cleared) {
      let currentItemCopy = { ...item, selectedSteps: [] };
      let stepChoice = {};
      item.steps.map((step) => {
        stepChoice = {
          title: step.title,
          options_limit: step.options_limit,
          currentOptions: [],
          ready_to_add: !step.required,
          built: false,
        };
        // Check if it was pushed already (protect against doble render)
        if (currentItemCopy.selectedSteps.findIndex((item) => item.title === stepChoice.title) === -1) {
          currentItemCopy.selectedSteps.push(stepChoice);
        }
      });
      setCurrentItem(currentItemCopy);
    }
  }, [cleared]);

  const resetAll = () => {
    // setAdded({});
    setPrice(item.price);
    setCleared(true);
    setCurrentItem(currentItemTemplate);
  };

  const handleNewItemAddition = () => {
    let itemsPreviewCopy = [...itemsPreview];
    itemsPreviewCopy.push({ ...currentItem, built: true });
    setItemsPreview(itemsPreviewCopy);
    setCurrentItem({ ...currentItem, built: true });
  };

  const handleBackToEdit = () => {
    let itemsPreviewCopy = [...itemsPreview];
    itemsPreviewCopy.splice(itemsPreviewCopy.indexOf(currentItem), 1);
    setItemsPreview(itemsPreviewCopy);
    setCurrentItem({ ...currentItem, built: false });
  };

  const checkAndSetPrice = () => {
    if (item.buildable_fixed_price) {
      return null;
    }
    // Item price insted of current item price because we want the base price
    let newPrice = item.price;
    for (const step of currentItem.selectedSteps) {
      for (const option of step.currentOptions) {
        newPrice += option.price;
      }
    }
    setPrice(newPrice);
  };

  useEffect(() => {
    if (currentItem) {
      let currentCopy = { ...currentItem };
      currentCopy.price = price;
      setCurrentItem(currentCopy);
    }
  }, [price]);

  // checks if current item has required steps picked

  useEffect(() => {
    if (currentItem) {
      checkAndSetReadyToAdd();
      checkAndSetIfCleared();
      checkAndSetPrice();
    }
    // if currentItem has changed and is no longer the same as currentItemTemplate, is not cleared
  }, [currentItem]);

  const checkAndSetIfCleared = () => {
    let cleared = true;
    for (const step of currentItem.selectedSteps) {
      // when cleared currentOptions is an empty Array
      if (step.currentOptions.length > 0) {
        cleared = false;
        break;
      }
    }

    setCleared(cleared);
  };

  const checkAndSetReadyToAdd = () => {
    let ready = true;
    for (const step of currentItem.selectedSteps) {
      if (!step.ready_to_add) {
        ready = false;
        break;
      }
    }
    setReadyToAdd(ready);
  };

  const renderCurrentItemSummary = (currentItem) => {
    return currentItem.selectedSteps.map((step) => {
      // loop through selectedSteps. If currentOptions > 0 means some options have been picked
      if (step.currentOptions.length > 0) {
        return (
          <div key={step.name}>
            <div className="underline">{step.title}</div>
            <ul>
              {step.currentOptions.map((option) => (
                <li key={option.name}>
                  → {option.name} {option.times_added > 1 && `(x${option.times_added})`}
                </li>
              ))}
            </ul>
          </div>
        );
      }
    });
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-3">
        {/* What is showed when the item is marked as ready */}
        {currentItem && currentItem.built ? (
          <div className="flex flex-col w-full justify-between items-start gap-2">
            <div className="flex justify-between gap-2 w-full">
              <p className={`text-left underline ${ColorPicker("text", colors.buttons)}`}>
                <strong>
                  {nameAndNumber} {g(item, "listo ✅", "lista ✅", "s")}
                </strong>
              </p>
              <Button
                variant="outlined"
                color={colors.buttons}
                size="sm"
                className="self-end border-dashed"
                onClick={() => handleBackToEdit()}
              >
                <span>Editar</span>
              </Button>
            </div>
            <RenderItems items={currentItem} />
          </div>
        ) : (
          <>
            <div className="text-md font-bold">
              Arma {g(item, "el", "la", "s")}{" "}
              <strong className={`underline ${ColorPicker("text", colors.buttons)}`}>{nameAndNumber}</strong>:
            </div>
            {/* Wait for currentItem to be ready before rendering StepPicker */}
            {currentItem &&
              currentItem.steps.map((step, index) => (
                <StepPicker
                  key={`${item.id}${index}`}
                  item={item}
                  step={step}
                  stepIndex={index}
                  currentItem={currentItem}
                  setCurrentItem={setCurrentItem}
                />
              ))}
            {!cleared && (
              <div>
                <div className="text-xs">
                  <p>
                    Resumen de{" "}
                    <strong className={`${ColorPicker("text", colors.buttons)} underline`}>{nameAndNumber}:</strong>
                  </p>
                  <div>{currentItem && renderCurrentItemSummary(currentItem)}</div>
                </div>
                <p className="text-xl font-bold underline underline-offset-4 text-right px-3">Precio: ${price}</p>
              </div>
            )}
            <div className="flex justify-between w-full items-center gap-2 mb-2">
              <Button
                variant="outlined"
                color={colors.negative}
                size="sm"
                className={`w-4/12 border-dashed ${cleared ? "grayscale" : null}`}
                onClick={resetAll}
                disabled={cleared}
              >
                Reiniciar
              </Button>
              <Button
                variant="outlined"
                color={colors.buttons}
                size="sm"
                className={`relative w-8/12 border-dashed ${readyToAdd ? null : "grayscale"}`}
                onClick={() => handleNewItemAddition()}
                disabled={!readyToAdd}
              >
                {g(item, "Listo", "Lista", "s")}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BuildItem;
