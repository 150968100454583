// store slice.
// fetches from app.pidamosgo.com/api/v1/sites/slug
// slug can be the slug of any restaurant
// it should fetch the restaurant info a make it available on the store

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
  rest: {},
  status: "idle",
};

export const restSlice = createSlice({
  name: "rest",
  initialState,
  reducers: {
    setData: (state, action) => {
      state.data = action.payload;
    },
    restore: (state) => {
      return initialState;
    },
    setMenuVersion: (state, action) => {
      state.menuVersion = true;
    },
    setSiteColors: (state, action) => {
      state.rest.siteColors = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchRest.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchRest.fulfilled, (state, action) => {
        state.rest = action.payload || {};
        // Let's try to dig in a bit in rest info, and see if it has any categories/menu. In order to give a better state already, and reduce checks on the components.
        // If it payload is empty, it's not_found
        // If it has a site, and it's active, it's loaded
        if(action.payload) {
          state.status = action.payload.site?.active ? 'loaded' : 'unavailable'
        } else {
          state.status = "not_found";
        }
      })
      .addCase(fetchRest.rejected, (state) => {
        state.rest = {};
        state.status = "not_found";
      });
  },
});

export const fetchRest = createAsyncThunk("rest/fetchAndSetRest", async (slug) => {
  const response = await fetch(`https://app.pidamosgo.com/api/business/${slug}`);
  const data = await response.json();
  return data;
});

export const { setMenuVersion, setSiteColors } = restSlice.actions;
export default restSlice.reducer;
