import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { ThemeProvider } from "@material-tailwind/react";
import { store } from "./app/store/store";
import WhatsAppFloater from "./app/components/WhatsAppFloater";
import App from "./App";
import Home from "./app/pages/Home";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import "tw-elements";
// import ReactGA from "react-ga";

// React Google Analytics
// ReactGA.initialize(process.env.REACT_APP_GOOGLE_TRACKING);
// ReactGA.pageview(window.location.pathname + window.location.search);

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ThemeProvider>
      <Provider store={store}>
        <WhatsAppFloater />
        <BrowserRouter>
          <Routes>
            <Route path="/:slug/*" element={<App />} />
            <Route path="/:slug/menu/*" element={<App menuVersion />} />
            <Route path="/" element={<Home />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(conchole.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
