import { useSelector } from "react-redux";
import CategoryItem from "./CategoryItem";
import ColorPicker from "../hooks/ColorPicker";

const Category = ({ category }) => {
  const colors = useSelector((state) => state.rest.rest.siteColors);
  const { position, name } = category;
  const items = category.items.filter((item) => item.active);

  const ariaExpandedBgColor =
    colors.card === "white" ? "aria-expanded:bg-white" : colors.card === "black" ? "aria-expanded:bg-black" : "";

  // make name sane so it can be a valid id and valid for selectors
  const idName = name.replace(/[^a-zA-Z0-9]/g, "-").toLowerCase();

  return (
    <div className="accordion-item border border-gray-200">
      <div className="accordion-header mb-0" id={`heading-${idName}-${position}`}>
        {/* Defines color of accordeon opener with the category title */}
        <button
          className={`accordion-button collapsed relative flex items-center w-full py-4 px-5 text-base text-left ${ColorPicker(
            "background",
            colors.card
          )} border-0 rounded-none transition focus:outline-none ${ariaExpandedBgColor}`}
          type="button"
          data-bs-toggle="collapse"
          data-bs-target={`#collapse-${idName}-${position}`}
          aria-expanded="false"
          aria-controls={`collapse-${idName}-${position}`}
        >
          {/* Category title color and style. We should define a color so it's not override whe accordeon open*/}
          <h4 className={`mr-3 font-bold text-2xl ${ColorPicker("text", colors.titles)}`}>{name}</h4>
        </button>
      </div>
      <div
        id={`collapse-${idName}-${position}`}
        // Defines bg color for all items inside when accordeon is open
        className={`accordion-collapse collapse ${ColorPicker("background", colors.card)}`}
        aria-labelledby={`heading-${idName}-${position}`}
      >
        <div className="accordion-body py-4 px-5">
          {category.image_url && (
            <div className="-mt-4">
              <div className="max-h-56 rounded-xl shadow-md overflow-hidden relative flex justify-center items-center">
                <img src={category.image_url} className="w-full" />
              </div>
              <hr className="pb-4 mt-3" />
            </div>
          )}
          {items.map((item) => (
            <CategoryItem
              key={item.id}
              category={category}
              item={item}
              hr={!(items.indexOf(item) === items.length - 1)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Category;
