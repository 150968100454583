import { useEffect, useState } from "react";
import { Checkbox } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import Price from "../hooks/Price";
import c from "../hooks/Capitalize";

const ExchangeIngridient = ({ exchangeable, option, handleExchange, exchanged }) => {
  const { rest } = useSelector((state) => state.rest);
  const [checked, setChecked] = useState(false);
  const colors = rest.siteColors;

  const newExchangeable = {
    id: exchangeable.id,
    name: exchangeable.name,
    default_option: exchangeable.default_option,
    option: option.name,
    price_cents: option.price_cents,
  };

  useEffect(() => {
    const index = exchanged.findIndex((it) => it.name === newExchangeable.name && it.option === newExchangeable.option);

    if (index === -1) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  }, [exchanged]);

  return (
    <div className="">
      <div className="flex justify-between items-center -mb-3">
        <p className="text-sm">
          {c(option.name)} - (${Price(option.price_cents)})
        </p>
        <Checkbox
          color={colors.brand}
          size="sm"
          onChange={() => {
            handleExchange(newExchangeable);
          }}
          checked={checked}
          className="bg-white w-4 h-4"
        />
      </div>
    </div>
  );
};

export default ExchangeIngridient;
