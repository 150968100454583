import { Avatar, Button, IconButton, menu } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addItem, removeItem, removeNote, updateStatus } from "../store/cartSlice";
import CustomizeForm from "./CustomizeForm";
import BuildForm from "./BuildForm";
import RenderItems from "./RenderItems";
import groupItems from "../hooks/GroupItems";
import CombosForm from "./CombosForm";
import NotesUI from "./NotesUI";
import ColorPicker from "../hooks/ColorPicker";
import Price from "../hooks/Price";

const CategoryItem = ({ category, item, hr }) => {
  const { menuVersion, rest } = useSelector((state) => state.rest);
  const colors = rest.siteColors;
  const dispatch = useDispatch();
  const [added, setAdded] = useState(false);
  const [count, setCount] = useState(0);
  const [customizedAdded, setCustomizedAdded] = useState(false);
  const [customModalId, setCustomModalId] = useState(null);
  const [customizedItemsOnCart, setCustomizedItemsOnCart] = useState(null);
  const [priceCustomizedOnCart, setPriceCustomizedOnCart] = useState(0);
  const [combosModalId, setCombosModalId] = useState(false);
  const [buildModalId, setBuildModalId] = useState(false);
  const [activeNotes, setActiveNotes] = useState(false);
  const [addedNote, setAddedNote] = useState(null);
  const { empty, status } = useSelector((state) => state.cart);
  const { name, description, price_cents, hasCombos, buildable, position, available } = item;
  const customizable = item.kind === "customizable";
  const image_url = item.image_url;

  const removeFormFromCart = () => {
    customizedItemsOnCart.map((it) => {
      dispatch(removeItem(it));
    });
    setCustomizedAdded(false);
  };

  useEffect(() => {
    if (customizable) {
      setCustomModalId(`customizing-modal-${category.name.replace(/\s/g, "")}-${name.replace(/\s/g, "")}-${position}`);
    }
    if (hasCombos) {
      setCombosModalId(`combos-modal-${category.name.replace(/\s/g, "")}-${name.replace(/\s/g, "")}-${position}`);
    }
    if (buildable) {
      setBuildModalId(`build-modal-${category.name.replace(/\s/g, "")}-${name.replace(/\s/g, "")}-${position}`);
    }
  }, []);

  const regularItem = {
    ...item,
    addedExtras: [],
    removedIngridients: [],
    exchangedIngridients: [],
    customized: false,
    addedCombo: {},
    comboed: false,
    built: false,
  };

  useEffect(() => {
    setAdded(Boolean(count));
  }, [count]);

  useEffect(() => {
    if (empty) {
      setCount(0);
    }
  }, [empty]);

  useEffect(() => {
    if (status === "cleared") {
      setCustomizedAdded(false);
    }
  }, [status]);

  const calculatePreviewPrice = (items) => {
    let price = 0;
    for (const it of items) {
      price += it.price_cents;
    }
    return price;
  };

  useEffect(() => {
    if (customizedAdded) {
      setPriceCustomizedOnCart(calculatePreviewPrice(customizedItemsOnCart));
    }
  });

  const handleNoteRemoval = () => {
    if (addedNote) {
      dispatch(removeNote(addedNote));
    }
  };

  useEffect(() => {
    if (!added && !customizedAdded) {
      handleNoteRemoval();
      setActiveNotes(false);
    }
  }, [added, customizedAdded]);

  return (
    // Color: for text of item title, price and description can be defined at this level
    <div className="category-item flex flex-col justify-between relative">
      <div className="item-wrapper relative">
        {image_url && <Avatar src={image_url} alt="avatar" size="xxl" href={image_url} className="float-left mr-2" />}
        <div className="description-buttons-wrapper">
          <div className="text-left">
            {/* Color for item title */}
            {/* Show price if is not 0. Some items like buildable can have price 0 */}
            <p className={`font-bold text-xl ${ColorPicker("text", colors.titles)}`}>{`${name} ${
              price_cents > 0 ? `-  $${Price(price_cents)}` : ""
            }`}</p>
            <p className="item-description text-left text-sm font-light">{description}</p>
          </div>

          <div className="w-full item-buttons-wrapper flex gap-2 justify-end">
            {added ? (
              <div className="w-full flex gap-2 justify-between mt-2">
                {activeNotes ? (
                  <Button
                    className="text-xs py-2 px-3"
                    variant="text"
                    color={colors.negative}
                    onClick={() => {
                      handleNoteRemoval();
                      setActiveNotes(false);
                      setAddedNote(null);
                    }}
                  >
                    Cancelar Nota
                  </Button>
                ) : (
                  <Button
                    className="text-xs py-2 px-3"
                    variant="text"
                    onClick={() => setActiveNotes(true)}
                    color={colors.brand}
                  >
                    Añadir Nota
                  </Button>
                )}

                <div className="flex gap-2 h-9">
                  <p className={`text-justify font-bold text-2xl ${ColorPicker("text", colors.brand, 400)}`}>
                    ${Price(price_cents * count)}
                  </p>
                  <IconButton
                    variant="outlined"
                    color={colors.negative}
                    className="flex-none"
                    size="sm"
                    onClick={() => {
                      setCount(count - 1);
                      dispatch(removeItem(regularItem));
                    }}
                  >
                    <p className="text-xl">-</p>
                  </IconButton>
                  <p className={`text-justify font-bold text-2xl ${ColorPicker("text", colors.brand, 200)}`}>
                    {`x${count}`}
                  </p>
                  <IconButton
                    variant="outlined"
                    color={colors.brand}
                    className="flex-none"
                    onClick={() => {
                      setCount(count + 1);
                      dispatch(addItem(regularItem));
                    }}
                    size="sm"
                  >
                    <p className="text-xl">+</p>
                  </IconButton>
                </div>
              </div>
            ) : (
              <div className={`flex w-full items-end grow justify-end gap-2`}>
                <div className="flex gap-2 h-9 mt-2">
                  {available && !menuVersion ? (
                    <>
                      {customizable && !customizedAdded && (
                        <Button
                          variant="outlined"
                          size="sm"
                          color={colors.brand}
                          onClick={() => {
                            dispatch(updateStatus("active"));
                          }}
                          className={`transition-all flex-none font-bold`}
                          data-bs-toggle="modal"
                          data-bs-target={`#${customModalId}`}
                        >
                          <span>Personalizar</span>
                        </Button>
                      )}
                      {hasCombos && !customizedAdded && (
                        <Button
                          variant="outlined"
                          size="sm"
                          color={colors.brand}
                          onClick={() => {
                            dispatch(updateStatus("active"));
                          }}
                          className="transition-all flex-none "
                          data-bs-toggle="modal"
                          data-bs-target={`#${combosModalId}`}
                        >
                          <span>En Combo</span>
                        </Button>
                      )}
                      {!customizedAdded && !buildable && (
                        <Button
                          variant="filled"
                          size="sm"
                          color={colors.brand}
                          onClick={() => {
                            setAdded(true);
                            setCount(count + 1);
                            dispatch(addItem(regularItem));
                          }}
                          className="transition-all flex-none"
                        >
                          <span>Añadir</span>
                        </Button>
                      )}
                      {buildable && !customizedAdded && (
                        <Button
                          variant="outlined"
                          size="sm"
                          color={colors.brand}
                          onClick={() => {
                            dispatch(updateStatus("active"));
                          }}
                          className="transition-all flex-none "
                          data-bs-toggle="modal"
                          data-bs-target={`#${buildModalId}`}
                        >
                          <span>Armar</span>
                        </Button>
                      )}
                    </>
                  ) : (
                    <>
                      {!available && (
                        <Button
                          variant="text"
                          size="sm"
                          color="red"
                          onClick={() => {
                            dispatch(updateStatus("active"));
                          }}
                          disabled
                        >
                          <span className="text-red-500">NO DISPONIBLE</span>
                        </Button>
                      )}
                    </>
                  )}
                </div>
              </div>
            )}
          </div>
          {/* Modals for custom, buildable and combos */}
          {customModalId && (
            <CustomizeForm
              item={regularItem}
              customizedAdded={customizedAdded}
              setCustomizedAdded={setCustomizedAdded}
              setCustomizedItemsOnCart={setCustomizedItemsOnCart}
              modalId={customModalId}
            />
          )}
          {combosModalId && (
            <CombosForm
              item={regularItem}
              customizedAdded={customizedAdded}
              setCustomizedAdded={setCustomizedAdded}
              setCustomizedItemsOnCart={setCustomizedItemsOnCart}
              modalId={combosModalId}
            />
          )}
          {buildModalId && (
            <BuildForm
              item={regularItem}
              customizedAdded={customizedAdded}
              setCustomizedAdded={setCustomizedAdded}
              setCustomizedItemsOnCart={setCustomizedItemsOnCart}
              modalId={buildModalId}
            />
          )}
        </div>
      </div>
      {customizedAdded && (
        <div className="w-full">
          <div
            className={`flex flex-col gap-3 p-4 mt-2 border border-dashed rounded-lg ${ColorPicker(
              "border",
              colors.brand,
              400
            )}`}
          >
            <p className={`text-center ${ColorPicker("text", colors.brand)}`}>
              Tus <strong>"{item.name}"</strong> se añadieron al pedido.
            </p>
            <div>
              <p className={`pb-1 font-bold ${ColorPicker("text", colors.brand)}`}>Resumen:</p>
              <RenderItems items={groupItems(customizedItemsOnCart)} />
              <p className="w-full text-right py-2 font-bold underline underline-offset-3">
                Sub-total: ${Price(priceCustomizedOnCart)}
              </p>
            </div>
            <Button
              variant="outlined"
              color={colors.negative}
              size="sm"
              className="relative w-full flex-1/2 border-dashed"
              onClick={removeFormFromCart}
            >
              Remover del pedido
            </Button>
          </div>
          <div className="w-full flex justify-end mt-2">
            {activeNotes ? (
              <Button
                className="text-xs py-2 px-3"
                variant="text"
                color={colors.negative}
                onClick={() => {
                  handleNoteRemoval();
                  setActiveNotes(false);
                  setAddedNote(null);
                }}
              >
                Cancelar Nota
              </Button>
            ) : (
              <Button
                className="text-xs py-2 px-3"
                variant="text"
                onClick={() => setActiveNotes(true)}
                color={colors.brand}
              >
                Añadir Nota
              </Button>
            )}
          </div>
        </div>
      )}
      {(added || customizedAdded) && (
        <>
          <div>
            {activeNotes ? (
              <div>
                <NotesUI
                  active={activeNotes}
                  item={item}
                  setAddedNote={setAddedNote}
                  handleNoteRemoval={handleNoteRemoval}
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </>
      )}

      {hr && <hr className="pb-4 mt-3" />}
    </div>
  );
};

export default CategoryItem;
