// TODO:
// Handle all posible colors comming from backend
// def available_colors
// %w(gray orange amber yellow lime green teal cyan blue indigo purple pink red)
// end

// Add defaults

const ColorPicker = (use, color, intensity) => {
  switch (use) {
    case "background":
      switch (color) {
        case "gray":
          switch (intensity) {
            case 300:
              return "bg-gray-300";
            case 50:
              return "bg-gray-50";
          }
        case "green":
          switch (intensity) {
            case 300:
              return "bg-green-300";
            case 50:
              return "bg-green-50";
          }
        case "blue":
          switch (intensity) {
            case 300:
              return "bg-blue-300";
            case 50:
              return "bg-blue-50";
          }
        case "red":
          switch (intensity) {
            case 300:
              return "bg-red-300";
            case 50:
              return "bg-red-50";
          }
        case "white":
          return "bg-white";
        case "black":
          return "bg-black";
        default:
          return "bg-gray-300";
      }
    case "text":
      switch (color) {
        case "gray":
          switch (intensity) {
            case 200:
              return "text-gray-200";
            case 400:
              return "text-gray-400";
            default:
              return "text-gray-600";
          }
        case "pink":
          switch (intensity) {
            case 200:
              return "text-pink-200";
            case 400:
              return "text-pink-400";
            default:
              return "text-pink-600";
          }
        case "blue":
          switch (intensity) {
            case 200:
              return "text-blue-200";
            case 400:
              return "text-blue-400";
            default:
              return "text-blue-600";
          }
        case "red":
          switch (intensity) {
            case 200:
              return "text-red-200";
            case 400:
              return "text-red-400";
            default:
              return "text-red-600";
          }
        case "green":
          switch (intensity) {
            case 200:
              return "text-green-200";
            case 400:
              return "text-green-400";
            default:
              return "text-green-600";
          }
        case "orange":
          switch (intensity) {
            case 200:
              return "text-orange-200";
            case 400:
              return "text-orange-400";
            default:
              return "text-orange-600";
          }
        case "amber":
          switch (intensity) {
            case 200:
              return "text-amber-200";
            case 400:
              return "text-amber-400";
            default:
              return "text-amber-600";
          }
        case "teal":
          switch (intensity) {
            case 200:
              return "text-teal-200";
            case 400:
              return "text-teal-400";
            default:
              return "text-teal-600";
          }
        case "lime":
          switch (intensity) {
            case 200:
              return "text-lime-200";
            case 400:
              return "text-lime-400";
            default:
              return "text-lime-600";
          }
        case "cyan":
          switch (intensity) {
            case 200:
              return "text-cyan-200";
            case 400:
              return "text-cyan-400";
            default:
              return "text-cyan-600";
          }
        case "indigo":
          switch (intensity) {
            case 200:
              return "text-indigo-200";
            case 400:
              return "text-indigo-400";
            default:
              return "text-indigo-600";
          }
        case "purple":
          switch (intensity) {
            case 200:
              return "text-purple-200";
            case 400:
              return "text-purple-400";
            default:
              return "text-purple-600";
          }
        case "white":
          return "text-white";
        case "black":
          return "text-black";
      }
      break;
    case "border":
      switch (color) {
        case "blue":
          switch (intensity) {
            case 400:
              return "border-blue-400";
            default:
              return "border-blue-600";
          }
        case "green":
          switch (intensity) {
            case 400:
              return "border-green-400";
            default:
              return "border-green-600";
          }
        case "red":
          switch (intensity) {
            case 400:
              return "border-red-400";
            default:
              return "border-red-600";
          }
        case "orange":
          switch (intensity) {
            case 400:
              return "border-orange-400";
            default:
              return "border-orange-600";
          }
        case "pink":
          switch (intensity) {
            case 400:
              return "border-pink-400";
            default:
              return "border-pink-600";
          }
        case "white":
          return "border-white";
        case "black":
          return "border-black";
      }
      break;
    default:
      return "";
  }
};

export default ColorPicker;
