import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  name: "",
  phone: "",
  address: "",
  saved: false,
};

export const clientSlice = createSlice({
  name: "client",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    getDataFromLocalStorage: (state) => {
      const data = JSON.parse(localStorage.getItem("pidamosgo-client"));
      if (data) {
        state.name = data.name;
        state.phone = data.phone;
        state.address = data.address;
        state.saved = state.name && state.phone;
      }
    },
    setDataToLocalStorage: (state) => {
      const data = {
        name: state.name,
        phone: state.phone,
        address: state.address,
        saved: state.name !== "" && state.phone,
      };
      localStorage.setItem("pidamosgo-client", JSON.stringify(data));
    },
    setName: (state, action) => {
      state.name = action.payload;
    },
    setPhone: (state, action) => {
      state.phone = action.payload;
    },
    setAddress: (state, action) => {
      state.address = action.payload;
    },
    setSaved: (state, action) => {
      state.saved = action.payload;
    },
    removeName: (state) => {
      state.name = "";
    },
    removePhone: (state) => {
      state.phone = "";
    },
    removeAddress: (state) => {
      state.address = "";
    },
    restore: (state) => {
      return initialState;
    },
  },
});

export const { setName, setPhone, setAddress, setSaved, getDataFromLocalStorage, setDataToLocalStorage } =
  clientSlice.actions;

export default clientSlice.reducer;
