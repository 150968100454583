import {
  Input,
  Textarea,
  Button,
  Radio,
  Checkbox,
} from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setDeliveryMethod } from "../store/cartSlice";
import { setName, setPhone, setAddress, setSaved, setDataToLocalStorage } from "../store/clientSlice";
import ColorPicker from "../hooks/ColorPicker";
import Address from "../hooks/Address";

const DeliveryForm = () => {
  const { rest } = useSelector((state) => state.rest);
  const colors = rest.siteColors;
  const { delivery } = useSelector((state) => state.cart);
  const { name, phone, address, saved } = useSelector((state) => state.client);
  const [nameError, setNameError] = useState(null);
  const [phoneError, setPhoneError] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const [remember, setRemember] = useState(true);

  const dispatch = useDispatch();

  const validateName = (name) => {
    if (name === "") {
      setNameError("Por favor ingresa tu nombre");
      return false;
    } else if (name.length < 3) {
      setNameError("Ingresa al menos 3 letras");
      return false;
    }
    setNameError("");
    dispatch(setName(name));
    return true;
  };

  const checkPhoneStart = (oldPhone) => {
    let check = false;
    let phone = oldPhone;
    if (phone[0] === "0") {
      phone = phone.slice(1, phone.length - 1);
    }
    const validStarts = ["412", "414", "424", "416", "426"];
    const phoneStart = phone.slice(0, 3);
    for (const st of validStarts) {
      if (phoneStart === st) {
        check = true;
        break;
      }
    }
    return check;
  };

  const validatePhone = (phone) => {
    if (phone === "") {
      setPhoneError("Por favor ingresa tu número con WhatsApp");
      return false;
    } else if (phone.length < 10) {
      setPhoneError("El número ingresado es muy corto");
      return false;
    } else if (!checkPhoneStart(phone)) {
      setPhoneError("El número ingresado no parece valido");
      return false;
    }
    setPhoneError("");
    dispatch(setPhone(phone));
    return true;
  };

  const validateAddress = (address) => {
    if (delivery !== "delivery") {
      setAddressError("");
      dispatch(setAddress(""));
      return true;
    }
    if (address === "") {
      setAddressError("Por favor ingresa tu dirección para el delivery");
      return false;
    }
    setAddressError("");
    dispatch(setAddress(address));
    return true;
  };

  const validateForm = (form) => {
    const {
      name: { value: newName },
      phone: { value: newPhone },
    } = form;

    let newAddress = true;

    if (delivery === "delivery") {
      newAddress = form.address.value;
    }

    const nameOk = validateName(newName);
    const phoneOk = validatePhone(newPhone);
    const addressOk = validateAddress(newAddress);

    if (nameOk && phoneOk && addressOk) {
      if (remember) dispatch(setDataToLocalStorage());
      return true;
    }
    return false;
  };

  const forceNumber = (e) => {
    let n = e.target.value;
    const reg = /\D/;
    e.target.value = n.replace(reg, "");
  };

  const handleSubmit = (e) => {
    const formNode = e.target.closest("form");

    if (validateForm(formNode)) {
      dispatch(setSaved(true));
    } else {
      dispatch(setSaved(false));
    }
  };

  const renderError = (error) => {
    return <p className={`w-full text-right italic ${ColorPicker("text", colors.negative, 400)} pb-1 pr-2`}>{error}</p>;
  };

  useEffect(() => {
    if (delivery === "delivery" && address === "") {
      dispatch(setSaved(false));
    }
  }, [delivery]);

  return (
    <form className="text-xs flex flex-col gap-4">
      <div className="flex gap-5">
        <Radio
          id="delivery"
          name="delivery"
          label="Delivery"
          onChange={() => dispatch(setDeliveryMethod("delivery"))}
          defaultChecked={delivery === "delivery"}
          color={colors.brand}
        />
        <Radio
          id="pickup"
          name="delivery"
          label="Pick-up"
          onChange={() => {
            setAddressError("");
            dispatch(setDeliveryMethod("pickup"));
          }}
          defaultChecked={delivery === "pickup"}
          color={colors.brand}
        />
      </div>
      <div>
        {nameError && renderError(nameError)}
        <Input
          name="name"
          label="Nombre"
          maxLength="30"
          disabled={saved}
          className="disabled:bg-gray-100"
          defaultValue={name || ""}
          color={colors.brand}
        />
      </div>
      <div>
        {phoneError && renderError(phoneError)}
        <Input
          name="phone"
          label="WhatsApp (Ej: 04128654729)"
          type="text"
          maxLength="11"
          onChange={forceNumber}
          disabled={saved}
          className="disabled:bg-gray-100"
          defaultValue={phone || ""}
          color={colors.brand}
        />
      </div>
      <div>
        {addressError && renderError(addressError)}
        {delivery === "delivery" ? (
          <Textarea
            name="address"
            label="Dirección para el delivery"
            maxLength="300"
            disabled={saved}
            className="disabled:bg-gray-100"
            defaultValue={address || ""}
            color={colors.brand}
          />
        ) : (
          <div>
            <p>
              <strong>🚗 Dirección para pick-up:</strong> {Address(rest.address)}
            </p>
          </div>
        )}
      </div>
      <Checkbox
        label="Recordar esta información para futuros pedidos"
        onClick={() => setRemember(!remember)}
        defaultChecked={true}
        color={colors.brand}
      />
      <div className="self-end flex items-center gap-3 italic">
        {saved ? (
          <>
            <p className={`${ColorPicker("text", colors.brand)}`}>Información guardada</p>
            <Button
              variant="outlined"
              color={colors.negative}
              onClick={() => dispatch(setSaved(false))}
              className="border-dashed py-2 px-3"
            >
              Editar
            </Button>
          </>
        ) : (
          <Button onClick={handleSubmit} color={colors.brand}>
            Guardar
          </Button>
        )}
      </div>
    </form>
  );
};

export default DeliveryForm;
