import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@material-tailwind/react";
import ExtraIngridient from "./ExtraIngridient";
import ExchangeIngridient from "./ExchangeIngridient";
import g from "../hooks/Genderize";
import UnwantedIngridient from "./UnwantedIngridient";
import RenderItems from "./RenderItems";
import ColorPicker from "../hooks/ColorPicker";
import Price from "../hooks/Price";

const CustomizeItem = ({ item, i, itemsPreview, setItemsPreview }) => {
  const { rest } = useSelector((state) => state.rest);
  const colors = rest.siteColors;
  const nameAndNumber = `${item.name} Nro ${i + 1}`;
  const [removed, setRemoved] = useState([]);
  const [added, setAdded] = useState([]);
  const [exchanged, setExchanged] = useState([]);
  const [price_cents, setprice_cents] = useState(item.price_cents);
  const [groupedExtras, setGroupedExtras] = useState(null);
  const [cleared, setCleared] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [currentItem, setCurrentItem] = useState({ ...item });

  const handleRemovable = (removable) => {
    if (removed.indexOf(removable) === -1) {
      setRemoved([...removed, removable]);
    } else {
      let removedCopy = [...removed];
      removedCopy.splice(removed.indexOf(removable), 1);
      setRemoved(removedCopy);
    }
  };

  const handleExchange = (exchangeable) => {
    // we could exchange many things in the same item
    // we need to check if the clicked exchangeable option belongs to an already exchanged ingridient
    // i.e. selecting a new option
    // or if the clicked exchangeable option is about another exchangeable ingridient
    const index = exchanged.findIndex((it) => it.name === exchangeable.name);

    if (index === -1) {
      // if here, it means that the selected option is about another exchangeable ingridient
      // i.e. not an already exchanged ingridient in our array
      setExchanged([...exchanged, exchangeable]);
      setprice_cents(price_cents + exchangeable.price_cents);
    } else {
      // if here, it means that the selected option is about an already exchanged ingridient
      // we're just changing the option
      let exchangedCopy = [...exchanged];
      const exRemoved = exchangedCopy.splice(index, 1);
      const price_to_remove = exRemoved[0].price_cents;

      // now we need to check if the clicked option is the same as the one already picked
      // i.e. the user is deselecting the option
      // if that's the case, then we need to remove the ingridient from the array and subtract the price
      // but if the user is selecting another option, then we need to add the price of the new option, and remove the price of the old one
      setprice_cents(price_cents - price_to_remove);

      if (exchanged[index].option !== exchangeable.option) {
        exchangedCopy.push(exchangeable);

        setprice_cents((prevState) => {
          return prevState + exchangeable.price_cents;
        });
      }
      setExchanged(exchangedCopy);
    }
  };

  const resetAll = () => {
    setRemoved([]);
    setAdded([]);
    setExchanged([]);
    setprice_cents(item.price_cents);
    setCleared(true);
  };

  useEffect(() => {
    if (removed.length > 0 || added.length > 0 || exchanged.length > 0) {
      setCleared(false);
    } else {
      setCleared(true);
    }
  }, [removed, added, exchanged]);

  const groupExtras = (added) => {
    let addedCopy = [...added];
    let extras = [];
    let index;
    let newItem;

    for (const added of addedCopy) {
      index = extras.findIndex((item) => item.name === added.name);
      if (index > -1) {
        newItem = { ...extras[index] };

        newItem.times += 1;

        extras.splice(index, 1, newItem);
      } else {
        extras.push({ ...added, times: 1 });
      }
    }
    return extras;
  };

  useEffect(() => {
    let itemCopy = { ...item };
    itemCopy.price_cents = price_cents;
    itemCopy = {
      ...itemCopy,
      addedExtras: groupExtras(added),
      removedIngridients: removed,
      exchangedIngridients: exchanged,
      customized: true,
    };
    setCurrentItem(itemCopy);
  }, [added, removed, exchanged, itemsPreview]);

  const handleNewItemAddition = () => {
    let itemsPreviewCopy = [...itemsPreview];
    itemsPreviewCopy.push(currentItem);
    setItemsPreview(itemsPreviewCopy);
    setCompleted(true);
  };

  const handleBackToEdit = () => {
    let itemsPreviewCopy = [...itemsPreview];
    itemsPreviewCopy.splice(itemsPreviewCopy.indexOf(currentItem), 1);
    setItemsPreview(itemsPreviewCopy);
    setCompleted(false);
  };

  useEffect(() => {
    setGroupedExtras(groupExtras(added));
  }, [added]);

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-3">
        {completed ? (
          <div className="flex flex-col w-full justify-between items-start gap-2">
            <div className="flex justify-between items-center gap-2 w-full">
              <div className={`${ColorPicker("text", colors.brand)} text-left`}>
                <strong>{nameAndNumber} </strong>
                <span> {g(item, "listo ✅", "lista ✅", "s")}</span>
              </div>
              <Button
                variant="outlined"
                color={colors.brand}
                size="sm"
                className="self-end border-dashed text-xs h-8"
                onClick={() => handleBackToEdit()}
              >
                <span>Editar</span>
              </Button>
            </div>
            <RenderItems items={currentItem} />
          </div>
        ) : (
          <>
            <div className="text-md font-bold">
              Personaliza {g(item, "el", "la", "s")}{" "}
              <strong className={`underline ${ColorPicker("text", colors.brand)}`}>{nameAndNumber}</strong>
            </div>
            {item.exchangeables && item.exchangeables.length > 0 && (
              <div className="-mx-1 p-1 py-1 rounded-lg">
                <p className={`text-sm font-bold -mb-1 ${ColorPicker("text", colors.brand)}`}>Intercambiar:</p>
                <div className="flex flex-col py-2">
                  {exchanged &&
                    item.exchangeables.map((ex) => {
                      return (
                        <div key={ex.name} className="-mr-2">
                          <p className="text-sm">
                            Puedes intercambiar{" "}
                            <span className="font-bold">
                              {ex.name} ({ex.default_option})
                            </span>{" "}
                            por:
                          </p>
                          {ex.options.map((op) => (
                            <ExchangeIngridient
                              key={op.id}
                              exchangeable={ex}
                              option={op}
                              handleExchange={handleExchange}
                              exchanged={exchanged}
                            />
                          ))}
                        </div>
                      );
                    })}
                </div>
              </div>
            )}
            {item.removables && item.removables.length > 0 && (
              <div className={` -mx-1 -mt-4 p-1 py-1 rounded-lg`}>
                <p className={`text-sm ${ColorPicker("text", colors.negative, 400)} font-bold -mb-1`}>Remover:</p>
                <div className="flex flex-col -mr-2">
                  {removed &&
                    item.removables.map((ingr) => {
                      return (
                        <UnwantedIngridient
                          key={ingr.id}
                          ingr={ingr}
                          handleRemovable={handleRemovable}
                          removed={removed}
                        />
                      );
                    })}
                </div>
              </div>
            )}
            {item.extras && item.extras.length > 0 && (
              <div className="flex flex-col -mx-1 -mt-2 p-1 py-1 rounded-lg">
                <p className={`text-sm font-bold ${ColorPicker("text", colors.brand)}`}>Añadir:</p>
                <div className="flex flex-col gap-3">
                  {item.extras.map((extra) => {
                    return (
                      <ExtraIngridient
                        key={extra.id}
                        extra={extra}
                        added={added}
                        setAdded={setAdded}
                        price_cents={price_cents}
                        setprice_cents={setprice_cents}
                        cleared={cleared}
                      />
                    );
                  })}
                </div>
              </div>
            )}
            <div className="text-xs">
              <p>
                Resumen de <strong className="underline">{nameAndNumber}:</strong>
              </p>
              <div className="py-1">
                {/* If nothing has been added, leave placeholder */}
                {exchanged.length === 0 && removed.length === 0 && currentItem.addedExtras.length === 0 && (
                  <p className="text-sm italic">Nada por ahora</p>
                )}
                {exchanged.length > 0 && (
                  <div className="flex flex-col">
                    {currentItem.exchangedIngridients.map((e) => (
                      <p key={e.id}>
                        🔄 Con {e.name} {e.option} en vez de {e.default_option} (${Price(e.price_cents)})
                      </p>
                    ))}
                  </div>
                )}
                {removed.length > 0 && (
                  <div className="flex flex-col">
                    {currentItem.removedIngridients.map((r) => (
                      <p key={r.id}>❌ Sin {r.name}</p>
                    ))}
                  </div>
                )}
                {groupedExtras && (
                  <div className="flex flex-col">
                    {currentItem.addedExtras.map((e) => (
                      <p key={e.name}>
                        ✅ Con extra de {e.name} x{e.times} ($
                        {Price(e.price_cents) * e.times})
                      </p>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <p className="text-xl font-bold underline underline-offset-4 text-right px-3">
              Precio: ${Price(price_cents)}
            </p>
            <div className="flex justify-between w-full items-center gap-2">
              <Button
                variant="outlined"
                color={colors.negative}
                size="sm"
                className={`w-4/12 border-dashed ${cleared ? "grayscale" : null}`}
                onClick={resetAll}
                disabled={cleared}
              >
                Reiniciar
              </Button>
              <Button
                variant="outlined"
                color={colors.brand}
                size="sm"
                className={`relative w-8/12 border-dashed ${cleared ? "grayscale" : null}`}
                onClick={() => handleNewItemAddition()}
                disabled={cleared}
              >
                {g(item, "Listo", "Listo", "s")}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CustomizeItem;
