// This function allows us to group the items on the cart
// If 2 items are exactly the same, with same ingridients,
// same combos or were build exactly the same
// we want to render 2 x item... insttead of rendering the both of them looking the same

const countIngridient = (arr, ingridient) => {
  let filtered = arr.filter((it) => it.name === ingridient.name);
  return filtered.length;
};

const compareIngridients = (item1, item2) => {
  const { addedExtras: added1, removedIngridients: removed1 } = item1;
  const { addedExtras: added2, removedIngridients: removed2 } = item2;

  if (added1.length === added2.length && removed1.length === removed2.length) {
    let allMatched = true;
    if (added1.length > 0 && added2.length > 0) {
      for (const it of added1) {
        if (countIngridient(added1, it) !== countIngridient(added2, it)) {
          allMatched = false;
        }
      }
    }
    if (removed1.length > 0 && removed2.length > 0) {
      for (const it of removed1) {
        if (countIngridient(removed1, it) !== countIngridient(removed2, it)) {
          allMatched = false;
        }
      }
    }

    return allMatched;
  } else {
    return false;
  }
};

const compareCombos = (item1, item2) => {
  if (!item1.comboed && !item2.comboed) {
    return true;
  } else {
    if (item1.addedCombo.name === item2.addedCombo.name) {
      return true;
    }
  }
  return false;
};

const compareExchanges = (item1, item2) => {
  if (
    // 2 arrays won't return === true because they are diffent objects
    // but if we make them both strings, we'd be comparing 2 strings that should match
    JSON.stringify(item1.exchangedIngridients.sort()) ===
    JSON.stringify(item2.exchangedIngridients.sort())
  ) {
    return true;
  } else {
    return false;
  }
};

const compareBuiltItems = (item1, item2) => {
  if (!item1.buildable && !item2.buildable) {
    // if both items aren't buildable, this comparision is true
    return true;
  } else if (item1.buildable && item2.buildable) {
    // if both are buildable, let's sort compare selected steps
    // selectedSteps should always be in same order
    let itemsMatch = true;
    for (let i = 0; i < item1.selectedSteps.length; i++) {
      // visit sorted currentOptions  of each step and compare their string version
      // if find one not matching, return false. On duplicates, they all should match
      let op1 = [...item1.selectedSteps[i].currentOptions];
      let op2 = [...item2.selectedSteps[i].currentOptions];
      if (JSON.stringify(op1.sort((a, b) => a.name > b.name)) != JSON.stringify(op2.sort((a, b) => a.name > b.name))) {
        return false;
      }
    }
    return itemsMatch;
  } else {
    // if the previous check didn't pass, means just one of them is buildable, hence not a duplicate
    return false;
  }
};

const groupItems = (items) => {
  let groupedItems = [];
  let index;

  for (const dish of items) {
    // try to find an item that looks exactly the same by comparing all possible variables
    // we are going to compare all items previously pushed to GroupedItems with every item on the ItemsPreview
    index = groupedItems.findIndex(
      (it) =>
        it.category === dish.category &&
        it.name === dish.name &&
        compareIngridients(it, dish) &&
        compareCombos(it, dish) &&
        compareExchanges(it, dish) &&
        compareBuiltItems(it, dish)
    );
    if (index > -1) {
      // if we find a duplicate, just increase the times and the price
      groupedItems[index].times += 1;
      groupedItems[index].price += dish.price;
    } else {
      // if we didn't find it, push it as a new item to the array
      groupedItems.push({ ...dish, times: 1 });
    }
  }
  return groupedItems;
};

export default groupItems;
