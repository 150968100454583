// a simple componetn that renders a black fading from transparent to white
// can be used at the bottom of images to make it fade to white

export default ({ height }) => {
  return (
    <div
      style={{
        display: "block",
        content: "yoloxx",
        position: "absolute",
        left: 0,
        bottom: 0,
        width: "100%",
        height: height || "100px",
        background: "linear-gradient(to bottom, rgba(255,255,255,0), white)",
      }}
    ></div>
  );
};
