import { useEffect, useState } from "react";
import { IconButton } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import c from "../hooks/Capitalize";
import ColorPicker from "../hooks/ColorPicker";
import Price from "../hooks/Price";

const ExtraIngridient = ({ added, setAdded, extra, price_cents, setprice_cents, cleared }) => {
  const { rest } = useSelector((state) => state.rest);
  const colors = rest.siteColors;
  const [count, setCount] = useState(0);

  const countIngredients = (arr, ing) => {
    let count = 0;
    for (const arrIng of arr) {
      if (arrIng === ing) {
        count += 1;
      }
    }
    return count;
  };

  useEffect(() => {
    setCount(countIngredients(added, extra));
  }, []);

  const addExtra = (extra) => {
    let addedCopy = [...added];
    addedCopy.push(extra);
    setAdded(addedCopy);
    setprice_cents(price_cents + extra.price_cents);
    setCount(count + 1);
  };

  const removeExtra = (extra) => {
    let addedCopy = [...added];
    addedCopy.splice(addedCopy.indexOf(extra), 1);
    setAdded(addedCopy);
    setprice_cents(price_cents - extra.price_cents);
    setCount(count - 1);
  };

  useEffect(() => {
    if (cleared) {
      setCount(0);
    }
  }, [cleared]);

  return (
    <div className="">
      <div className="flex justify-between items-center text-sm">
        <div>
          <span>{c(extra.name)}</span> - ${Price(extra.price_cents)}{" "}
          <span className="text-xs italic">(Max. {extra.limit})</span>
        </div>
        <div className="flex gap-2 items-center justify-center">
          <IconButton
            variant="outlined"
            color={count === 0 ? "gray" : colors.negative}
            className={`flex-none w-6 h-6 ${count === 0 ? "border-gray-400 " : null}`}
            size="sm"
            onClick={() => removeExtra(extra)}
            disabled={count === 0 ? true : false}
          >
            <p className="text-xl">-</p>
          </IconButton>
          <p className={`text-justify ${ColorPicker("text", colors.brand)}`}>{count}</p>
          <IconButton
            variant="outlined"
            color={count === extra.limit ? "gray" : colors.brand}
            className={`flex-none w-6 h-6 ${count === extra.limit ? "border-gray-400 " : null}`}
            onClick={() => addExtra(extra)}
            size="sm"
            disabled={count === extra.limit ? true : false}
          >
            <p className="text-xl">+</p>
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default ExtraIngridient;
