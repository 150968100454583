import groupItems from "./GroupItems";
import emojiMap from "./emojiMap";
import Price from "./Price";
import Address from "./Address";

const renderExchangedIngridients = (ingridients) => {
  let text = "";
  ingridients.forEach((e) => {
    text += `\n🔄 Con ${e.option} en vez de ${e.name}`;
  });
  return text;
};

const renderRemovedIngridients = (ingridients) => {
  let text = "\n❌ Sin: ";
  ingridients.forEach((i) => {
    text += `*${i.name}*`;
    text +=
      ingridients.indexOf(i) === ingridients.length - 1
        ? "."
        : ingridients.indexOf(i) === ingridients.length - 2
        ? "ni"
        : ",";
  });
  return text;
};

const renderExtraIngridients = (ingridients) => {
  let text = "\n✅ Con:\n";
  ingridients.forEach((i) => {
    text += `*${i.name}* x ${i.times}`;
    text +=
      ingridients.indexOf(i) === ingridients.length - 1
        ? "."
        : ingridients.indexOf(i) === ingridients.length - 2
        ? "y"
        : ",";
  });
  return text;
};

// For buildable items

const renderSelectedSteps = (steps) => {
  let text = "\n";
  for (const step of steps) {
    text += `*${step.title}:*\n`;
    for (const option of step.currentOptions) {
      text += `${option.name}`;
      if (option.times_added > 1) {
        text += ` (x${option.times_added})`;
      }
      text += "\n";
    }
  }
  text += "\n";
  return text;
};

const renderEmoji = (itemIcon) => {
  // check if current browser/client is instagram
  // if so, return empty string since they will navigate to browser whatsapp and icons will be strange symbols
  const isInstagram = navigator.userAgent.includes("Instagram");
  if (isInstagram) return "";

  // also check if using Android or iOS, which are the only cases where we would like to render icons
  const isAndroid = navigator.userAgent.includes("Android");
  const isIOS = navigator.userAgent.includes("iPhone");
  if (!isAndroid && !isIOS) return "";

  if (!itemIcon) return "";

  return emojiMap[itemIcon];
};

const renderItems = (items) => {
  let text = "";

  items.forEach((it) => {
    text += `${renderEmoji(it.icon)} *${it.name}* - $${Price(it.price_cents)} x ${it.times}   \`\`\`($${Price(
      it.price_cents * it.times
    )})\`\`\``;

    if (it.addedExtras.length > 0 || it.removedIngridients.length > 0 || it.exchangedIngridients.length > 0) {
      text += " (personalizada)";
      if (it.exchangedIngridients.length > 0) {
        text += renderExchangedIngridients(it.exchangedIngridients);
      }

      if (it.removedIngridients.length > 0) {
        text += renderRemovedIngridients(it.removedIngridients);
      }

      if (it.addedExtras.length > 0) {
        text += renderExtraIngridients(it.addedExtras);
      }
    }

    if (it.addedCombo && it.addedCombo.name) {
      text += `\n💕 ${it.addedCombo.name}`;
    }

    if (it.buildable && it.built) {
      text += renderSelectedSteps(it.selectedSteps);
    }
    text += "\n";
  });

  return text;
};

const renderDeliveryMethod = (rest, client, cart) => {
  let text = "";
  if (cart.delivery === "delivery") {
    text += "*La dirección de envío es:*\n";
    text += client.address.replaceAll("\n", " ");
    text += "\n¿Cuál sería el costo?";
  }

  if (cart.delivery === "pickup") {
    text += "*Este pedido es para pick-up.*\n";
    text += "Por favor confirmar la dirección:\n";
    text += Address(rest.address);
  }

  return text;
};

const renderPaymentMethod = (rest, client, cart) => {
  let text = "";
  if (cart.payment !== "otro") {
    text += `*Quisiera pagar con ${cart.payment.name}.*\n`;
    text += `Por favor confirmar la información:\n${cart.payment.info}`;
  } else {
    text += "*Quisiera información sobre sus métodos de pago.*";
  }
  return text;
};

const renderNotes = (notes) => {
  let text = `${notes.length > 1 ? "*Notas*" : "*Nota*"}:\n`;
  notes.forEach((n) => {
    text += `✏️ Sobre ${renderEmoji(n.icon)} ${n.item}: `;
    text += n.note;
    // text += "\n";
  });
  return text;
};

const createWhatsAppMsg = (rest, client, cart) => {
  let msg = `Hola *${rest.name}*! ${renderEmoji("wave")}\n`;
  msg += `Soy *${client.name.trim()}.*\n\n`;
  msg += "*Quisiera realizar este pedido:*\n";
  msg += renderItems(groupItems(cart.items));
  msg += "\n";
  if (cart.notes.length > 0) {
    msg += renderNotes(cart.notes);
    msg += "\n\n";
  }
  msg += `Por favor confirmar el total: *$${Price(cart.subTotal)}*`;
  // Removed check if free delivery since not implemented on backend
  if (cart.delivery === "delivery") {
    msg += " (sin contar el delivery)";
  }
  msg += "\n\n";
  msg += renderDeliveryMethod(rest, client, cart);
  msg += "\n\n";
  msg += renderPaymentMethod(rest, client, cart);
  msg += "\n\n";
  msg += `Espero su pronta respuesta ${renderEmoji("thumbsup")}`;

  return msg;
};

export default createWhatsAppMsg;
