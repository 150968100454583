import { Card, CardHeader, CardBody, CardFooter } from "@material-tailwind/react";
import businessIsOpen from "../hooks/businessIsOpen";
import { useSelector } from "react-redux";
import { openHoursToday } from "../hooks/Schedule";
import ColorPicker from "../hooks/ColorPicker";

export default function Example() {
  const { rest } = useSelector((state) => state.rest);
  const colors = rest.siteColors;
  const { name, description, manual_schedule, manual_schedule_open } = rest;
  const isOpen = manual_schedule ? manual_schedule_open : businessIsOpen(rest.open_days_in_order);
  const todaySchedule = manual_schedule ? false : openHoursToday(rest.open_days_in_order);

  return (
    // Color: Define background color bg and text for business card
    <Card className={`w-full ${ColorPicker("background", colors.card)} ${ColorPicker("text", colors.text)}`}>
      {rest.image_url && (
        <CardHeader
          className="max-h-56 rounded-xl shadow-md overflow-hidden relative flex justify-center items-center"
          floated={false}
        >
          <img src={rest.image_url} alt="business-header" className="w-full" />
        </CardHeader>
      )}
      <CardBody className="text-center">
        <h1 className={`text-3xl font-bold mb-2 ${ColorPicker("text", colors.titles)}`}>{name}</h1>
        <p className="font-light tracking-wide">{description}</p>
      </CardBody>
      <CardFooter
        divider
        className={`flex items-center justify-between py-3 font-light text-xs ${
          isOpen ? "text-green-500" : "text-red-400"
        }`}
      >
        <span className="w-full text-center font-bold">{isOpen ? "ABIERTO" : "CERRADO"}</span>
        {todaySchedule && <span className="w-full font-bold">{todaySchedule}</span>}
      </CardFooter>
    </Card>
  );
}
