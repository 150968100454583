const wsMsg = (number, msg) => {
  const baseURL = "https://wa.me/";

  let newFormat = encodeURIComponent(msg);

  const text = `?text=${newFormat}`;
  const link = baseURL + number + text;

  window.open(link, "_blank");
};

export default wsMsg;
