import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  delivery: "delivery",
  subTotal: 0,
  empty: true,
  status: "cleared",
  items: [],
  notes: [],
  payment: null,
};

const handleItemRemoval = (state, item) => {
  let newState = [...state];
  const index = newState.findIndex(
    (it) => JSON.stringify(it) === JSON.stringify(item)
  );
  newState.splice(index, 1);
  return newState;
};

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    activate: (state) => {
      state.empty = false;
    },
    setDeliveryMethod: (state, action) => {
      state.delivery = action.payload;
    },
    deacivate: (state) => {
      state.empty = true;
    },
    addItem: (state, action) => {
      state.empty = false;
      state.subTotal += action.payload.price_cents;
      state.items = [...state.items, action.payload];
    },
    removeItem: (state, action) => {
      state.subTotal -= action.payload.price_cents;
      state.items = handleItemRemoval(state.items, action.payload);
      state.empty = state.items.length === 0;
    },
    incrementByAmount: (state, action) => {
      state.value += action.payload;
    },
    clear: () => {
      return initialState;
    },
    updateStatus: (state, action) => {
      state.status = action.payload;
    },
    addNote: (state, action) => {
      state.notes = [...state.notes, action.payload];
    },
    removeNote: (state, action) => {
      state.notes = state.notes.filter((it) => JSON.stringify(it) !== JSON.stringify(action.payload));
    },
    setPaymentMethod: (state, action) => {
      state.payment = action.payload;
    },
  },
});

export const {
  activate,
  deacivate,
  addItem,
  removeItem,
  clear,
  incrementByAmount,
  updateStatus,
  addNote,
  removeNote,
  setDeliveryMethod,
  setPaymentMethod,
} = cartSlice.actions;

export default cartSlice.reducer;
