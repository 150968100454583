import RenderedItem from "./RenderedItem";

const RenderItems = ({ items, withEmojies }) => {
  return (
    <ul className="w-full grow">
      {/* Check if we need to render multiple items or just one */}
      {items.map ? (
        <div className="">
          {items.map((it) => (
            <div key={JSON.stringify(it)} className="flex flex-col gap-2">
              <RenderedItem key={JSON.stringify(it)} item={it} withEmojies={withEmojies} />
              <hr
                key={`${it.id}${it.category}${it.name}hr`}
                className="border-top-2 border-dashed border-gray-400 p-1"
              />
            </div>
          ))}
        </div>
      ) : (
        // If we only need to render one, add times key since when its multiple they have it?
        <RenderedItem key={JSON.stringify(items)} item={{ ...items, times: 1 }} withEmojies={withEmojies} />
      )}
    </ul>
  );
};

export default RenderItems;
