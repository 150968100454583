// Handle Address like {
// address: {
//   street: "Avenida Bolivar",
//   city: "Caracas",
//   state: "Capital District",
//   country: "Venezuela",
//   zip: "1010",
//   note: "En la tercera esquina, luego de la segunda casa, donde vive Juancho.",
// },
// and return a string with the street and the note.

const Address = (addressObject) => {
  const { street, note } = addressObject;
  let address = street;
  if (note) {
    address += `\n${note}`;
  }
  return address;
};

export default Address;
