// receives an schedule like this
// schedule: [
//   {
//     id: 980190962,
//     day: "lunes",
//     opening_hour: "2000-01-01T08:00:00.000Z",
//     closing_hour: "2000-01-01T20:00:00.000Z",
//     business_id: 980190962,
//     created_at: "2023-09-06T03:39:21.347Z",
//     updated_at: "2023-09-06T03:39:21.347Z",
//   },
//   {
//     id: 298486374,
//     day: "martes",
//     opening_hour: "2000-01-01T08:00:00.000Z",
//     closing_hour: "2000-01-01T20:00:00.000Z",
//     business_id: 980190962,
//     created_at: "2023-09-06T03:39:21.347Z",
//     updated_at: "2023-09-06T03:39:21.347Z",
//   },
//   {
//     id: 113629430,
//     day: "miércoles",
//     opening_hour: "2000-01-01T08:00:00.000Z",
//     closing_hour: "2000-01-01T22:30:00.000Z",
//     business_id: 980190962,
//     created_at: "2023-09-06T03:39:21.347Z",
//     updated_at: "2023-09-06T03:39:21.347Z",
//   },
// ],
// and defines if the business is open at the moment

const businessIsOpen = (schedule) => {
  if (!schedule) return false;
  const weekDays = ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"];

  const currentDay = new Date().getDay();
  const givenDayObject = schedule.find((day) => day.day === weekDays[currentDay]);
  if (!givenDayObject) return false;

  const openingDate = new Date(givenDayObject.opening_hour);
  const openingHour = openingDate.getUTCHours();
  const openingMinutes = openingDate.getUTCMinutes();

  const closingDate = new Date(givenDayObject.closing_hour);
  const closingHour = closingDate.getUTCHours();
  const closingMinutes = closingDate.getUTCMinutes();

  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  const currentMinutes = currentDate.getMinutes();

  // e.g. if it opens wednesday at 8pm and closes thursday at 2am
  // we dont have the closing hour since that would belong to the next day
  // so we if current time is after opening hour and before closing hour of this day
  const nightShift = closingHour < openingHour;
  const isOpeningHour = currentHour === openingHour;
  const isClosingHour = currentHour === closingHour;

  if (!nightShift) {
    if (currentHour < openingHour || currentHour > closingHour) return false;
    if (isOpeningHour) {
      return currentMinutes >= openingMinutes;
    } else if (isClosingHour) {
      return currentMinutes < closingMinutes;
    }
  } else {
    //// CHECK IF THIS IS CORRECT
    if (currentHour < openingHour && currentHour > closingHour) return false;
    if (currentHour === openingHour && currentMinutes < openingMinutes) return false;
    if (currentHour === closingHour && currentMinutes >= closingMinutes) return false;
  }

  return true;
};

export default businessIsOpen;
