import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, Button } from "@material-tailwind/react";
import g from "../hooks/Genderize";
import RenderItems from "./RenderItems";
import groupItems from "../hooks/GroupItems";
import DeliveryForm from "./DeliveryForm";
import PaymentForm from "./PaymentForm";
import wsMsg from "../hooks/WsMsg";
import createWhatsAppMsg from "../hooks/createWhatsAppMsg";
import emojiMap from "../hooks/emojiMap";
import ColorPicker from "../hooks/ColorPicker";
import Price from "../hooks/Price";
import CopyToClipboard from "../hooks/CopyToClipboard";
import { setName, setPhone, setAddress, setSaved, setDataToLocalStorage } from "../store/clientSlice";

const Checkout = ({ ref2 }) => {
  const { rest } = useSelector((state) => state.rest);
  const colors = rest.siteColors;
  const cart = useSelector((state) => state.cart);
  const client = useSelector((state) => state.client);
  const [orderSent, setOrderSent] = useState(false);
  const [orderMessage, setOrderMessage] = useState("");
  const [messageCopied, setMessageCopied] = useState(false);
  const { items, subTotal, notes } = cart;
  const { name, phone, saved } = client;

  const dispatch = useDispatch();

  // Create PreOrder in back-end
  const createPreOrder = () => {
    // check if orderSent because we dont want to create multiple records for the same preOrder
    if (!rest || !name || !phone || orderSent) return;

    fetch(`https://app.pidamosgo.com/api/business/${rest.slug}/pre_order`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        pre_order: {
          customer_name: name,
          customer_phone: phone,
        },
      }),
    });
  };

  const handleSend = () => {
    const message = createWhatsAppMsg(rest, client, cart);
    setOrderMessage(message);
    wsMsg(rest.phone_number, message);
    createPreOrder();
    setOrderSent(true);
  };

  const handleMessageCopy = () => {
    CopyToClipboard(orderMessage);
    setMessageCopied(true);
  };

  const resetClientInfo = () => {
    dispatch(setName(""));
    dispatch(setPhone(""));
    dispatch(setAddress(""));
    dispatch(setSaved(false));
    dispatch(setDataToLocalStorage());
    setOrderSent(false);
    setMessageCopied(false);
  };

  return (
    <div
      className={`modal fade fixed bottom-0 left-0 hidden w-full h-full outline-none py-5 backdrop-blur-sm`}
      id="checkoutModal"
      tabIndex="-1"
      aria-labelledby="checkoutModalLabel"
      aria-hidden="true"
    >
      <div className={`modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none`}>
        <div
          className={`modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-clip-padding rounded-lg outline-none  ${ColorPicker(
            "background",
            colors.card
          )}`}
        >
          {/* header */}
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md shadow-md">
            <h1 className="font-bold">Finaliza tu pedido</h1>
          </div>
          {/* body */}
          <div className="modal-body relative p-4 overflow-x-hidden overflow-y-auto shadow-inner">
            {orderSent ? (
              <div className="w-full flex flex-col gap-2">
                <h2 className="font-bold">Pedido enviado</h2>
                <span>Mensaje:</span>
                <div className="w-full rounded-lg border border-gray-400 bg-gray-100 overflow-auto p-2">
                  <pre className="whitespace-pre-wrap text-xs">{orderMessage}</pre>
                </div>
                <div className="w-full flex justify-between mt-2">
                  <Button color={colors.negative} variant="outlined" onClick={resetClientInfo}>
                    Editar datos
                  </Button>
                  <Button onClick={() => handleMessageCopy()} variant="outlined" color={colors.brand}>
                    {messageCopied ? "✅ Copiar Mensaje" : "Copiar Mensaje"}
                  </Button>
                </div>
              </div>
            ) : (
              <div className="w-full flex flex-col gap-6">
                <h2 className="font-bold">1. Revisa tu pedido:</h2>
                <RenderItems items={groupItems(items)} />
                <p className="self-end font-bold text-lg">SubTotal: ${Price(subTotal)}</p>
                {notes.length > 0 && (
                  <div className="w-full flex flex-col gap-2 -mt-3">
                    <h3 className="font-bold">Tus notas:</h3>
                    <ul className="text-xs">
                      {notes.map((note) => (
                        <li key={note.note}>
                          <p>
                            <strong>
                              ✏️ Sobre {emojiMap[note.icon]} {note.item}:{" "}
                            </strong>
                            {note.note}
                          </p>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <hr />
                <div className="w-full flex flex-col gap-3">
                  <h2 className="font-bold">2. Datos de entrega:</h2>
                  <DeliveryForm />
                </div>
                <hr />
                <div>
                  <PaymentForm />
                </div>
              </div>
            )}
          </div>

          {/* Footer */}
          <div className="modal-footer flex flex-col gap-2 items-center justify-end p-4 border-t border-gray-200 rounded-b-md shadow-md text-xs">
            <div className="w-full text-right">
              {" "}
              {!saved && (
                <p className={`italic ${ColorPicker("text", colors.negative, 400)}`}>Guarda los datos de entrega</p>
              )}
            </div>
            <div className="w-full flex justify-end gap-3 items-center">
              <Button color={colors.negative} data-bs-dismiss="modal">
                Cerrar
              </Button>
              <Button
                color={colors.brand}
                onClick={handleSend}
                disabled={!saved}
                className="flex justify-center items-center gap-2 flex-none"
              >
                <p>{orderSent ? "Abrir WhatsApp" : "Enviar pedido"}</p>
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Checkout;
