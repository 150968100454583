import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "@material-tailwind/react";
import c from "../hooks/Capitalize";

const Combo = ({ combo, handleComboPick, currentCombo }) => {
  const colors = useSelector((state) => state.rest.rest.siteColors);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (currentCombo === combo) {
      setChecked(true);
    } else {
      setChecked(false);
    }
  }, [currentCombo]);

  return (
    <div className="">
      <div className="flex justify-between items-center -mb-3">
        <p className="text-sm">{c(combo.name)}</p>
        <div>
          (+${combo.price})
          <Checkbox
            color={colors.buttons}
            size="sm"
            onChange={() => handleComboPick(combo)}
            checked={checked}
            className="bg-white w-4 h-4"
          />
        </div>
      </div>
    </div>
  );
};

export default Combo;
