import { IconButton, Typography } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ColorPicker from "../hooks/ColorPicker";

const StepOptionMultipleButton = ({
  option,
  handleRepeatableOptionPick,
  originalStep,
  optionsSelectedCount,
  added,
  currentStep,
}) => {
  const colors = useSelector((state) => state.rest.rest.siteColors);
  const options_limit = originalStep.options_limit;

  // count can be set to 0 on iniization
  // because the rendering of this component can happen after clicking Edit
  const calculateAddedCount = () => {
    let count = 0;
    // loop through current options in currentStep
    for (const addedOption of currentStep.currentOptions) {
      // at this point, finding an option means it's added already
      // check the name to see if it is the same option
      if (addedOption.name === option.name) {
        // a match means that this option has been added before
        // count should be the times this option was added, fetching times_added
        count = addedOption.times_added;
      }
    }
    return count;
  };

  const [count, setCount] = useState(calculateAddedCount());

  return (
    <div className="h-8 flex items-center">
      {added ? (
        <div className="flex items-center gap-2">
          <IconButton
            variant="outlined"
            color={colors.negative}
            className="flex-none w-5 h-5"
            size="sm"
            onClick={() => {
              setCount(count - 1);
              handleRepeatableOptionPick(option, "sub");
            }}
          >
            <p className="text-xl">-</p>
          </IconButton>
          <Typography variant="h4" className={`text-md text-justify ${ColorPicker("text", colors.buttons)}`}>
            {`x${count}`}
          </Typography>
          <IconButton
            variant="outlined"
            color={optionsSelectedCount >= options_limit ? "gray" : colors.buttons}
            className="flex-none w-5 h-5"
            onClick={() => {
              setCount(count + 1);
              handleRepeatableOptionPick(option, "add");
            }}
            size="sm"
            //if reached options_limit dont offer to add more
            disabled={optionsSelectedCount >= options_limit}
          >
            <p className="text-xl">+</p>
          </IconButton>
        </div>
      ) : (
        <IconButton
          variant="outlined"
          className="flex-none w-5 h-5"
          onClick={() => {
            setCount(1);
            handleRepeatableOptionPick(option, "add");
          }}
          size="sm"
          disabled={optionsSelectedCount >= options_limit}
          color={optionsSelectedCount >= options_limit ? "gray" : colors.buttons}
        >
          <p className="text-xl">+</p>
        </IconButton>
      )}
    </div>
  );
};

export default StepOptionMultipleButton;
