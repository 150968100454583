import faceEmojies from "../fake_data/faceEmojies";
import emojiMap from "../hooks/emojiMap";
import pickRandom from "../hooks/randomInt";
import ColorPicker from "../hooks/ColorPicker";
import Price from "../hooks/Price";
import { useSelector } from "react-redux";

// const rest = useSelector((state) => state.rest);

const RenderedItem = ({ item, withEmojies }) => {
  const colors = useSelector((state) => state.rest.rest.siteColors);
  return (
    <li className="flex flex-col w-full">
      <div className="flex flex-col">
        <div className="flex flex-col items-start justify-between w-full gap-1 ">
          {/* Flex top part */}
          <div className="w-full flex justify-between items-center">
            {/* Flex left column of top part, for item number and name */}
            <div className="number-and-x flex gap-1">
              <span role="img" className="mr-0.5">
                {emojiMap[item.icon]}
              </span>
              <span>
                {item.name} - ${Price(item.price_cents)}
              </span>
              <span>{"x"}</span>
              <span>{item.times}</span>
              {/* Customized items have extras or removed ingridients */}
              {/* If was customized, add "personalizada" label */}
              <strong className="">
                {(item.addedExtras || item.removedIngridients) && (
                  <span className="text-xs">
                    {item.addedExtras.length > 0 ||
                    item.removedIngridients.length > 0 ||
                    item.exchangedIngridients.length > 0
                      ? "(personalizada)"
                      : ""}{" "}
                  </span>
                )}
              </strong>
              {/* If with emojies, we render random emojies with each */}
              {withEmojies && pickRandom(faceEmojies)}
            </div>
            {/* Flex right column of top part usually for price */}
            <span className="font-bold">{`$${Price(item.price_cents * item.times)}`}</span>
          </div>
          {/* Flex bottom part column to display item's info */}
          <div className="itemem-name-and-extras grow">
            <div>
              {item.exchangedIngridients && item.exchangedIngridients.length > 0 && (
                <div>
                  {item.exchangedIngridients.map((e) => (
                    <p key={e.option} className="text-xs">
                      🔄 Con {e.name} {e.option} en vez de {e.default_option}
                    </p>
                  ))}
                </div>
              )}
              {/* For removed ingridients */}
              {item.removedIngridients && item.removedIngridients.length > 0 && (
                <div className="flex flex-wrap gap-1 text-xs">
                  <p className={`${ColorPicker("text", colors.negative, 400)}`}>Sin:</p>
                  {item.removedIngridients.map((ingridient) => (
                    <p key={ingridient.name}>
                      <strong>{ingridient.name}</strong>
                      {item.removedIngridients.indexOf(ingridient) === item.removedIngridients.length - 1
                        ? "."
                        : item.removedIngridients.indexOf(ingridient) === item.removedIngridients.length - 2
                        ? " ni"
                        : ","}
                    </p>
                  ))}
                </div>
              )}
            </div>
            <div>
              {item.addedExtras && item.addedExtras.length > 0 && (
                <div className="flex flex-wrap gap-1 text-xs">
                  <p className={`${ColorPicker("text", colors.brand)}`}>Con extra de:</p>
                  {item.addedExtras.map((extra) => (
                    <p key={extra.name}>
                      <strong>{extra.name}</strong> x {extra.times}
                      {item.addedExtras.indexOf(extra) === item.addedExtras.length - 1
                        ? "."
                        : item.addedExtras.indexOf(extra) === item.addedExtras.length - 2
                        ? " y"
                        : ","}
                    </p>
                  ))}
                </div>
              )}
              {/* For added combos */}
              {item.addedCombo && item.addedCombo.name && <p className="text-xs">💕 {item.addedCombo.name}</p>}
            </div>
            {/* For buildable items */}
            <div>
              {item.buildable && item.built && (
                <div>
                  <ul className="text-xs">
                    {item.selectedSteps.map((step) => (
                      <li key={step.title}>
                        <p className="underline">{step.title}</p>
                        {step.currentOptions.map((option) => (
                          <p key={option.name}>
                            → {option.name} {option.times_added > 1 && <span>(x{option.times_added})</span>}
                          </p>
                        ))}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </li>
  );
};

export default RenderedItem;
