const foodEmojis = {
  avocado: "🥑",
  meatball: "🥟",
  rice: "🍚",
  rice_with_chicken: "🍛",
  rice_pudding: "🍧",
  brownie: "🍫",
  burrito: "🌯",
  calamari: "🦑",
  roast_beef: "🥩",
  onion: "🧅",
  chocolate: "🍫",
  churro: "🥜",
  egg: "🥚",
  food: "🥣",
  rabbit: "🐰",
  cream: "🥧",
  croquette: "🥟",
  salad: "🥗",
  asparagus: "🥒",
  noodles: "🍜",
  steak: "🥩",
  strawberry: "🍓",
  cookies: "🍪",
  hamburger: "🍔",
  ice_cream: "🍨",
  fried_egg: "🍳",
  ingredient: "🥣",
  lemon: "🍋",
  apple: "🍎",
  margarine: "🧈",
  mayonnaise: "🥫",
  melon: "🍈",
  jams: "🥫",
  honey: "🍯",
  sausage: "🥟",
  mustard: "🥫",
  orange: "🍊",
  nut: "🌰",
  olive: "🫒",
  potato: "🥔",
  bread: "🥐",
  pineapple: "🍍",
  hot_dog: "🌭",
  pizza: "🍕",
  chicken: "🐔",
  dessert: "🥧",
  cheese: "🧀",
  sauce: "🥫",
  salmon: "🐟",
  sandwich: "🥪",
  soup: "🥣",
  veal: "🐄",
  tomato: "🍅",
  tortilla: "🥙",
  grapes: "🍇",
  vanilla: "🥧",
  carrot: "🥕",
  beer: "🍺",
  wine: "🍷",
  water: "🥛",
  coffee: "☕️",
  cocktail: "🍸",
  drink: "🥤",
};

const otherEmojis = {
  laught: "😀",
  smile: "🙂",
  music: "🎵",
  car: "🚗",
  map: "🗺",
  pin: "📍",
  watch: "⌚️",
  clock: "🕰",
  phone: "📱",
  computer: "💻",
  wave: "👋",
  bulb: "💡",
  question: "❓",
  warning: "⚠️",
  check: "✅",
  cross: "❌",
  x: "❌",
  pencil: "✏️",
  pen: "🖋️",
  cog: "⚙️",
  browser: "🌐",
  thumbsup: "👍",
};

// Merge all emojis
const emojiMap = {
  ...foodEmojis,
  ...otherEmojis,
};

export default emojiMap;
