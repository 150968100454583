import { useEffect, useState } from "react";
import StepOption from "./StepOption";

const StepOptions = ({
  item,
  step,
  stepIndex,
  currentItem,
  setCurrentItem,
  optionsSelectedCount,
  setOptionsSelectedCount,
  currentStep,
}) => {
  // const [currentOption, setCurrentOption] = useState({});
  const { options, required, options_required, options_limit } = step;
  const [price, setPrice] = useState(item.price);
  // to count how many options has been selected and compare with options_limit of the step

  const handleOptionPick = (option) => {
    // try to find the option on selectedSteps
    const index =
      currentItem.selectedSteps[stepIndex].currentOptions.indexOf(option);
    if (index > -1) {
      // if the option is there, remove it
      removeOptionFromSelectedStep(index);
      setOptionsSelectedCount((prevCount) => prevCount - 1);
    } else {
      // if we can only pick 1, remove the one selected, set counter to 0
      if (options_limit === 1) {
        removeOptionFromSelectedStep(0);
        setOptionsSelectedCount((prevCount) => prevCount * 0);
      } else if (optionsSelectedCount >= options_limit) {
        // if the options_limit was reached already, remove the first one added, and lower 1 the count
        // can't do previos step in single step so in one way or the other a prevcount)=>prevcount happens and the seter below work
        removeOptionFromSelectedStep(0);
        setOptionsSelectedCount((prevCount) => prevCount - 1);
      }
      // add the clicked option to the current selected step
      addOptionToSelectedStep(option);
      setOptionsSelectedCount((prevCount) => prevCount + 1);
    }
  };

  // handles clickon plus or minus buttons on options that can be reapeated
  const handleRepeatableOptionPick = (option, buttonClicked) => {
    let index;
    if (buttonClicked === "add") {
      // try to find an already added element with same name as the current option
      index = currentItem.selectedSteps[stepIndex].currentOptions.findIndex(
        (el) => el.name === option.name
      );
      if (index > -1) {
        repeatOptionOnSelectedStep(index, option);
      } else {
        addOptionToSelectedStep({ ...option, times_added: 1 });
      }
      setOptionsSelectedCount(optionsSelectedCount + 1);
    } else if (buttonClicked === "sub") {
      //if button clicked was to substract
      // find the element based on name
      const currentOptions =
        currentItem.selectedSteps[stepIndex].currentOptions;
      index = currentOptions.findIndex((op) => op.name === option.name);
      // if times_added of the current option === 1 (just 1 left) means it needs to be removed
      if (currentOptions[index].times_added <= 1) {
        removeOptionFromSelectedStep(index);
      } else {
        // if times added >= 1 then just remove 1 instance
        removeRepetitionFromOption(index, option);
      }
      setOptionsSelectedCount(optionsSelectedCount - 1);
    }
  };

  const addOptionToSelectedStep = (option) => {
    // make copy of current Item
    let currentItemCopy = { ...currentItem };
    // find selectedSteps with same index as step and push the clicked option into current Options
    currentItemCopy.selectedSteps[stepIndex].currentOptions.push(option);
    setCurrentItem(currentItemCopy);
  };

  const removeOptionFromSelectedStep = (optionIndex) => {
    // make copy of current Item
    let currentItemCopy = { ...currentItem };
    // find selectedSteps with same index as step and splice the clicked option
    currentItemCopy.selectedSteps[stepIndex].currentOptions.splice(
      optionIndex,
      1
    );
    setCurrentItem(currentItemCopy);
  };

  const repeatOptionOnSelectedStep = (optionIndex, option) => {
    let currentItemCopy = { ...currentItem };
    // find currentOption selectedSteps with same index as step and change the 1 time, and reduce price
    // option is the original option, we'll use it to have the price to add
    let currentOptionCopy =
      currentItemCopy.selectedSteps[stepIndex].currentOptions[optionIndex];
    currentOptionCopy.times_added += 1;
    currentOptionCopy.price += option.price;
    setCurrentItem(currentItemCopy);
  };

  const removeRepetitionFromOption = (optionIndex, option) => {
    let currentItemCopy = { ...currentItem };
    // find currentOption selectedSteps with same index as step and remove 1 time, and reduce price
    // option is the original option, we'll use it to adjust the price
    let currentOptionCopy =
      currentItemCopy.selectedSteps[stepIndex].currentOptions[optionIndex];
    currentOptionCopy.times_added -= 1;
    currentOptionCopy.price -= option.price;
    setCurrentItem(currentItemCopy);
  };

  const clearCurrentOptions = () => {
    // make copy of current Item
    let currentItemCopy = { ...currentItem };
    // find selectedSteps with same index as step and splice the clicked option
    currentItemCopy.selectedSteps[stepIndex].currentOptions = [];
    setCurrentItem(currentItemCopy);
    setOptionsSelectedCount(0);
  };

  return (
    <div className="flex flex-col gap-2 p-1">
      {options.map((op) => (
        <StepOption
          key={op.id}
          option={op}
          handleOptionPick={handleOptionPick}
          handleRepeatableOptionPick={handleRepeatableOptionPick}
          currentItem={currentItem}
          originalStep={step}
          currentStep={currentStep}
          optionsSelectedCount={optionsSelectedCount}
        />
      ))}
    </div>
  );
};

export default StepOptions;
