import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import "./WhatsAppFloater.css";

const WhatsAppFloater = () => {
  const [isVisible, setIsVisible] = useState(true);
  const [wsNumber, setWsNumber] = useState("584126963069");
  const [ipAddress, setIpAddress] = useState("");
  const { rest, status } = useSelector((state) => state.rest);

  const shadowClass =
    "shadow-[0_2.8px_2.2px_rgba(0,_0,_0,_0.034),_0_6.7px_5.3px_rgba(0,_0,_0,_0.048),_0_12.5px_10px_rgba(0,_0,_0,_0.06),_0_22.3px_17.9px_rgba(0,_0,_0,_0.072),_0_41.8px_33.4px_rgba(0,_0,_0,_0.086),_0_100px_80px_rgba(0,_0,_0,_0.12)]";

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => setIpAddress(data.ip))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    let timeoutId;

    const handleVisibility = (e) => {
      // check if the clicked element is this component. In that case, do nothing.
      if (e.target.classList && e.target.classList.contains("whatsapp-floater")) return;
      setIsVisible(false);
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => setIsVisible(true), 1500);
    };

    window.addEventListener("scroll", handleVisibility);
    window.addEventListener("touchstart", handleVisibility);

    return () => {
      window.removeEventListener("scroll", handleVisibility);
      window.removeEventListener("touchstart", handleVisibility);
      clearTimeout(timeoutId);
    };
  }, []);

  useEffect(() => {
    if (status === "loaded" && rest?.phone_number) {
      setWsNumber(rest.phone_number);
    }
  }, [status]);

  const handleClick = () => {
    // register whatsapp visit in backend
    if (rest?.slug) {
      fetch(`https://app.pidamosgo.com/api/business/${rest?.slug}/whatsapp_visit`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          details: ipAddress,
        }),
      });
    }

    // open whatsapp link
    window.open(`https://wa.me/${wsNumber}`, "_blank");
  };

  return (
    <div
      className={`whatsapp-floater z-50 h-14 w-14 fixed bottom-24 right-1 bg-green-500 text-white rounded-full p-2 flex items-center justify-between ${shadowClass} ${
        isVisible ? "animate-floater-show" : "animate-floater-hide"
      }`}
      draggable="true"
      onAbort={handleClick}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="currentColor"
        className="w-full p-1 whatsapp-floater"
        onClick={handleClick}
      >
        <path
          onClick={handleClick}
          className="whatsapp-floater"
          d="M.057 24l1.687-6.163c-1.041-1.804-1.588-3.849-1.587-5.946.003-6.556 5.338-11.891 11.893-11.891 3.181.001 6.167 1.24 8.413 3.488 2.245 2.248 3.481 5.236 3.48 8.414-.003 6.557-5.338 11.892-11.893 11.892-1.99-.001-3.951-.5-5.688-1.448l-6.305 1.654zm6.597-3.807c1.676.995 3.276 1.591 5.392 1.592 5.448 0 9.886-4.434 9.889-9.885.002-5.462-4.415-9.89-9.881-9.892-5.452 0-9.887 4.434-9.889 9.884-.001 2.225.651 3.891 1.746 5.634l-.999 3.648 3.742-.981zm11.387-5.464c-.074-.124-.272-.198-.57-.347-.297-.149-1.758-.868-2.031-.967-.272-.099-.47-.149-.669.149-.198.297-.768.967-.941 1.165-.173.198-.347.223-.644.074-.297-.149-1.255-.462-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.297-.347.446-.521.151-.172.2-.296.3-.495.099-.198.05-.372-.025-.521-.075-.148-.669-1.611-.916-2.206-.242-.579-.487-.501-.669-.51l-.57-.01c-.198 0-.52.074-.792.372s-1.04 1.016-1.04 2.479 1.065 2.876 1.213 3.074c.149.198 2.095 3.2 5.076 4.487.709.306 1.263.489 1.694.626.712.226 1.36.194 1.872.118.571-.085 1.758-.719 2.006-1.413.248-.695.248-1.29.173-1.414z"
        />
      </svg>
    </div>
  );
};

export default WhatsAppFloater;
