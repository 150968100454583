import pidamosGoLogoWhite from "../images/pidamosgo_logo_white.png";

export default ({ setShowNavbar }) => {
  return (
    <>
      <div className="fixed left -top-2 h-40 w-full z-50 max-w-[400px] bg-sky-500 p-4 -ml-4"></div>
      <div className="w-full max-w-[400px] h-screen z-50 fixed top left bg-sky-500 flex flex-col gap-8 -ml-4">
        <div className="text-white w-full flex justify-end" onClick={() => setShowNavbar(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            className="h-6 w-6 mr-4"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </div>
        <div className="pl-4 text-2xl font-bold gap-8 underline text-white flex flex-col justify-start w-full text-right pr-8">
          <div>
            <a href={"https://app.pidamosgo.com"} target="_blank">
              Aplicación
            </a>
          </div>
          <div>
            <a href={"https://wa.me/+584126963069"} target="_blank">
              Contacto
            </a>
          </div>
          <div>
            <a href={"https://guias.pidamosgo.com"} target="_blank">
              Guías y tutoriales
            </a>
          </div>
          <div>
            <a href={"https://www.pidamosgo.com/ejemplo"} target="_blank">
              Menú ejemplo
            </a>
          </div>
        </div>

        <div className="w-full flex justify-center items-center mt-24">
          <img src={pidamosGoLogoWhite} alt="PidamosGO Logo" className="w-[210px]" />
        </div>
      </div>
    </>
  );
};
