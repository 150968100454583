import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Radio } from "@material-tailwind/react";
import { setPaymentMethod } from "../store/cartSlice";
import { useEffect } from "react";

const PaymentForm = () => {
  const { rest } = useSelector((state) => state.rest);
  const [currentMethod, setCurrentMethod] = useState(rest.payment_methods[0]);
  const dispatch = useDispatch();
  const colors = rest.siteColors;

  useEffect(() => {
    dispatch(setPaymentMethod(currentMethod));
  }, []);

  useEffect(() => {
    dispatch(setPaymentMethod(currentMethod));
  }, [currentMethod]);

  return (
    <>
      {rest.payment_methods && (
        <div>
          <h2 className="font-bold">3. Método de pago:</h2>
          <ul className="text-xs">
            {rest.payment_methods.map((method) => (
              <Radio
                key={method.name}
                id={method.name}
                name="method"
                label={method.name}
                defaultChecked={currentMethod.name === method.name}
                onClick={() => setCurrentMethod(method)}
                color={colors.brand}
              />
            ))}
            <Radio
              id="otro"
              name="method"
              label="Otro"
              defaultChecked={currentMethod?.name === "otro"}
              onClick={() => setCurrentMethod("otro")}
              color={colors.brand}
            />
          </ul>
        </div>
      )}
    </>
  );
};

export default PaymentForm;
