import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addItem, removeItem } from "../store/cartSlice";
import { IconButton, Button } from "@material-tailwind/react";
import g from "../hooks/Genderize";
import CombosForItem from "./CombosForItem";
import RenderItems from "./RenderItems";
import groupItems from "../hooks/GroupItems";
import ColorPicker from "../hooks/ColorPicker";

const CombosForm = ({ item, customizedAdded, setCustomizedAdded, setCustomizedItemsOnCart, modalId }) => {
  const colors = useSelector((state) => state.rest.rest.siteColors);
  const dispatch = useDispatch();
  const [itemsTotal, setItemsTotal] = useState(1);
  const [customizedTotal, setCustomizedTotal] = useState(1);
  const [step, setStep] = useState(1);
  const [helperArray, setHelperArray] = useState([...Array(1)]);
  const [itemsPreview, setItemsPreview] = useState([]);
  const [customizedAddedTotal, setCustomizedAddedTotal] = useState(0);
  const [previewPrice, setPreviewPrice] = useState(item.price);
  const [reseted, setReseted] = useState(true);

  useEffect(() => {
    setHelperArray([...Array(customizedTotal)]);
  }, [customizedTotal]);

  useEffect(() => {
    let previewCopy = [...itemsPreview];
    previewCopy = previewCopy.filter((it) => it != item);
    let normalNumber = itemsTotal - customizedTotal;
    for (let i = 0; i < normalNumber; i++) {
      previewCopy.push(item);
    }
    setItemsPreview(previewCopy);
  }, [itemsTotal, customizedTotal]);

  const calculateCustomizedAddedTotal = () => {
    let count = 0;
    for (const it of itemsPreview) {
      if (it.addedCombo && it.addedCombo.name) {
        count += 1;
      }
    }
    return count;
  };

  const calculatePreviewPrice = (items) => {
    let price = 0;
    for (const it of items) {
      price += it.price;
    }
    return price;
  };

  useEffect(() => {
    setCustomizedAddedTotal(calculateCustomizedAddedTotal());
    setPreviewPrice(calculatePreviewPrice(itemsPreview));
  }, [itemsPreview]);

  const addFormToCart = () => {
    itemsPreview.map((it) => {
      dispatch(addItem(it));
    });
    setCustomizedAdded(true);
    setCustomizedItemsOnCart(itemsPreview);
  };

  const removeFormFromCart = () => {
    itemsPreview.map((it) => {
      dispatch(removeItem(it));
    });
    setCustomizedAdded(false);
  };

  const reset = () => {
    setItemsTotal(1);
    setCustomizedTotal(1);
    setStep(1);
    setHelperArray([...Array(1)]);
    setItemsPreview([]);
    setCustomizedAddedTotal(0);
    setPreviewPrice(item.price);
  };

  useEffect(() => {
    if (itemsTotal > 1 || customizedTotal > 1 || step > 1) {
      setReseted(false);
    } else {
      setReseted(true);
    }
  }, [itemsTotal, customizedTotal, step]);

  useEffect(() => {
    if (!customizedAdded) {
      reset();
    }
  }, [customizedAdded]);

  return (
    <div
      className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none py-5 backdrop-blur-sm"
      id={modalId}
      tabIndex="-1"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none">
        <div
          className={`modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto ${ColorPicker(
            "background",
            colors.card
          )}  bg-clip-padding rounded-lg outline-none`}
        >
          {/* header */}
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md shadow-md">
            <div>
              {step === 1 && (
                <p className="text-md">
                  <strong className="font-bold">PASO 1:</strong> <span>Escoge el total.</span>
                </p>
              )}
              {step === 2 && (
                <p className="text-sm pb-1">
                  <strong>PASO 2:</strong> <span>Escoge el combo para cada "{item.name}".</span>
                </p>
              )}
              {step === 3 && (
                <p className="text-sm ">
                  <strong>PASO 3:</strong> <span>Confirma la información y añade al pedido.</span>
                </p>
              )}
            </div>
            <Button variant="outlined" size="sm" color={colors.negative} data-bs-dismiss="modal">
              Cancelar
            </Button>
          </div>
          {/* Modal body */}
          <div
            className={`step-1 flex flex-col gap-3 p-4 border  
                border-dashed
                ${ColorPicker("border", colors.buttons)}
                rounded-lg`}
          >
            {step === 1 && (
              <div
                className="step-1 flex flex-col gap-3 py-5 px-2 border  
              border-dashed
              border-gray-400
              rounded-lg"
              >
                <div className="step-1-a flex justify-between items-center gap-1">
                  <p className="text-sm">
                    ¿{g(item, "Cuántos", "Cuántas", "p")} "<strong>{item.name}</strong>" deseas en total, sumando
                    {g(item, "solos", "solas", "p")} y en combo?
                  </p>
                  <div className="flex flex-col items-rigth">
                    <p className="font-bold italic text-right">En total:</p>
                    <div className="flex gap-1 items-center justify-center">
                      <IconButton
                        variant="outlined"
                        color={itemsTotal === 1 ? "gray" : colors.negative}
                        className={`flex-none w-6 h-6 ${itemsTotal === 1 ? "border-gray-400" : null}`}
                        size="sm"
                        onClick={() => {
                          setItemsTotal(itemsTotal - 1);
                          if (itemsTotal <= customizedTotal) {
                            setCustomizedTotal(customizedTotal - 1);
                          }
                        }}
                        disabled={itemsTotal === 1}
                      >
                        <p className="text-xl">-</p>
                      </IconButton>
                      <p className="w-6 h-7 border rounded-lg font-bold text-center">{itemsTotal}</p>
                      <IconButton
                        variant="outlined"
                        color={colors.buttons}
                        className="flex-none w-6 h-6"
                        onClick={() => setItemsTotal(itemsTotal + 1)}
                        size="sm"
                        disabled={false}
                      >
                        <p className="text-xl">+</p>
                      </IconButton>
                    </div>
                  </div>
                </div>
                <hr />
                <div className="step-1-b flex justify-between items-center gap-1">
                  <p className="text-sm">
                    De {itemsTotal} "<strong>{item.name}</strong>", ¿{g(item, "cuántos", "cuántas", "p")} deseas en
                    combo?
                  </p>
                  <div className="flex flex-col items-end justify-center">
                    <p className="font-bold italic text-right">Combos:</p>
                    <div className="flex gap-1 items-center">
                      <IconButton
                        variant="outlined"
                        color={customizedTotal <= 1 ? "gray" : colors.negative}
                        className={`flex-none w-6 h-6 ${customizedTotal <= 1 ? "border-gray-400 " : null}`}
                        size="sm"
                        onClick={() => setCustomizedTotal(customizedTotal - 1)}
                        disabled={customizedTotal <= 1}
                      >
                        <p className="text-xl">-</p>
                      </IconButton>
                      <p className="w-6 h-7 border rounded-lg font-bold text-center">{customizedTotal}</p>
                      <IconButton
                        variant="outlined"
                        color={customizedTotal >= itemsTotal ? "gray" : colors.buttons}
                        className={`flex-none w-6 h-6 ${customizedTotal >= itemsTotal ? "border-gray-400 " : null}`}
                        onClick={() => setCustomizedTotal(customizedTotal + 1)}
                        size="sm"
                        disabled={customizedTotal >= itemsTotal}
                      >
                        <p className="text-xl">+</p>
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {step === 2 && (
              <div
                className="step-2
              flex
              flex-col
              gap-3
              py-5
              px-2
              border  
              border-dashed
              border-gray-400
              rounded-lg"
              >
                <div className="flex flex-col gap-5">
                  {helperArray.map((_, i) => (
                    <div key={`${item.id}${i}`}>
                      <CombosForItem
                        key={`${item.id}${i}`}
                        item={item}
                        i={i}
                        itemsPreview={itemsPreview}
                        setItemsPreview={setItemsPreview}
                      />
                      {i < helperArray.length - 1 && <hr className="border-top-2 border-gray-200" />}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {step === 3 && (
              <div
                className="step-3
              flex
              flex-col
              gap-3
              py-5
              px-2
              border 
              border-dashed  
              rounded-lg
              border-gray-400"
              >
                {customizedAdded && (
                  <p className={`${ColorPicker("text", colors.buttons)} text-center`}>
                    Tus <strong>"{item.name}"</strong> se añadieron al pedido.
                  </p>
                )}
                <div>
                  <p className={`pb-1 font-bold ${ColorPicker("text", colors.buttons)}`}>Resumen:</p>
                  <RenderItems items={groupItems(itemsPreview)} />
                  <p
                    className={`w-full text-right px-2 font-bold underline underline-offset-3 ${ColorPicker(
                      "text",
                      colors.buttons
                    )}`}
                  >
                    Sub-total: ${previewPrice}
                  </p>
                </div>
              </div>
            )}
          </div>
          {/* Footer */}
          <div
            className={`modal-footer w-full ${ColorPicker(
              "background",
              colors.card
            )} flex gap-2 items-center justify-end p-4 border-t border-gray-200 rounded-b-md shadow-md`}
          >
            <Button
              variant="gradient"
              size="sm"
              color={reseted ? "gray" : colors.negative}
              onClick={() => reset()}
              className={`border-dashed grow ${reseted ? "grayscale" : null}`}
              disabled={reseted}
            >
              <span>Reiniciar</span>
            </Button>
            <div className="flex w-2/3">
              {step === 1 && (
                <Button variant="gradient" color={colors.buttons} size="sm" className="grow" onClick={() => setStep(2)}>
                  Continuar
                </Button>
              )}
              {step === 2 && (
                <Button
                  variant="gradient"
                  color={customizedAddedTotal < customizedTotal ? "gray" : colors.buttons}
                  size="sm"
                  className="grow"
                  onClick={() => setStep(3)}
                  disabled={customizedAddedTotal < customizedTotal}
                >
                  Continuar
                </Button>
              )}
              {step === 3 && (
                <Button
                  variant="gradient"
                  color={colors.buttons}
                  size="sm"
                  className="relative w-full flex-1/2"
                  onClick={addFormToCart}
                  data-bs-dismiss="modal"
                >
                  Añadir al pedido
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CombosForm;
