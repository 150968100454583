import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "../../features/counter/counterSlice";
import cartReducer from "./cartSlice";
import clientReducer from "./clientSlice";
import restReducer from "./restSlice";

export const store = configureStore({
  reducer: {
    counter: counterReducer,
    cart: cartReducer,
    client: clientReducer,
    rest: restReducer,
  },
});
