import { useState, useEffect } from "react";
import { Navbar as TailwindNavbar, MobileNav, Button, IconButton, Avatar } from "@material-tailwind/react";
import SvgIcon from "../hooks/SvgIcon";
import ColorPicker from "../hooks/ColorPicker";
import Address from "../hooks/Address";
import Schedule from "../hooks/Schedule";

export default function Navbar({ rest }) {
  const colors = rest.siteColors;
  const [openNav, setOpenNav] = useState(false);
  const hasSchedule = rest.open_days_in_order.length > 0;
  const siteLinks = rest.site.links;

  const liClass = "flex items-center";

  const navList = (
    <ul className="w-full flex flex-col items-center p-2  fonts-poppins font-bold gap-2">
      {siteLinks.map((link) => (
        <a href={link.safe_url} className={liClass} key={`${link.name}${link.safe_url}`} target="_blank">
          {link.name}
        </a>
      ))}
    </ul>
  );

  return (
    <div className="!opacity-100">
      {/* Color: Text color on this container will define Navbar color for title burger icon and childrens */}
      <TailwindNavbar
        className={`mx-auto max-w-screen-xl py-2 px-4 ${ColorPicker("background", colors.card)} ${ColorPicker(
          "text",
          colors.titles
        )} !bg-opacity-100`}
      >
        <div className="container mx-auto flex items-center justify-between ">
          {/* TODO: Change for business logo or default logo */}
          {rest.logo_url && (
            <div className="max-w-8">
              <Avatar src={rest.logo_url} alt="avatar" className="w-auto" />
            </div>
          )}
          <span className="ml-2 text-xl font-bold">{rest.name}</span>
          <IconButton
            variant="text"
            className="ml-auto h-6 w-6 text-inherit hover:bg-transparent focus:bg-transparent active:bg-transparent"
            ripple={false}
            onClick={() => setOpenNav(!openNav)}
          >
            {openNav ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                className="h-6 w-6"
                viewBox="0 0 24 24"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
              </svg>
            ) : (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
              </svg>
            )}
          </IconButton>
        </div>
        <MobileNav
          variant="filled"
          open={openNav}
          shodow={false}
          blurred={false}
          // Color: Text color at this level will define color for links inside dropdown
          className="px-2"
        >
          {navList}
          {/* // Color: define color for button on navbar */}
          <a href={`https://wa.me/${rest.phone_number}`} target="_blank" className="w-full">
            <Button
              variant="gradient"
              size="sm"
              fullWidth
              className="relative flex justify-center items-center text-center"
              color="green"
            >
              <span>Whatsapp</span>
              <span className="absolute h-6 w-6 right-2 text-white">{SvgIcon("whatsapp")}</span>
            </Button>
          </a>
          <hr className="my-2" />
          <div className="text-xs">
            <p className="font-bold">📍 Dirección:</p>
            {Address(rest.address)}
          </div>
          {hasSchedule && (
            <>
              <hr className="my-2" />
              <div className="text-xs">
                <p className="font-bold">⏱ Horario de atención:</p>
                {Schedule(rest.open_days_in_order)}
              </div>
            </>
          )}
        </MobileNav>
      </TailwindNavbar>
    </div>
  );
}
