import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import StepOptions from "./StepOptions";
import ColorPicker from "../hooks/ColorPicker";

const StepPicker = ({ item, step, stepIndex, currentItem, setCurrentItem }) => {
  const colors = useSelector((state) => state.rest.rest.siteColors);
  const { title, options_limit, options_required, options, required } = step;
  const [currentStep, setCurrentStep] = useState(currentItem.selectedSteps[stepIndex]);

  // Show minimum when its required but minimum required is different from maximum you can pick
  const showMinimum = required && options_limit != options_required;

  const [stepIsReady, setStepIsReady] = useState(step.ready_to_add);

  // We need to calculated the count if currentItem comes from clicking Editing
  // Need to define the function before using it
  const calculateSelectedOptionsCount = () => {
    let count = 0;
    for (const option of currentStep.currentOptions) {
      // if option.times_added exist, means this option can be selected multiple times
      // like an ingridient. If not, it's just one option to count
      if (option.times_added) {
        count += option.times_added;
      } else {
        count += 1;
      }
    }
    return count;
  };

  const [optionsSelectedCount, setOptionsSelectedCount] = useState(calculateSelectedOptionsCount());

  // check if step is good to go
  useEffect(() => {
    // check only if required=true because otherwise it should be ready
    if (required && optionsSelectedCount >= options_required) {
      setStepIsReady(true);
    } else {
      setStepIsReady(false);
    }
  }, [currentItem]);

  // if stepIsReady, update the current step on current Item
  useEffect(() => {
    let itemCopy = { ...currentItem };
    if (stepIsReady) {
      itemCopy.selectedSteps[stepIndex].ready_to_add = true;
      setCurrentItem(itemCopy);
    } else {
      itemCopy.selectedSteps[stepIndex].ready_to_add = false;
      setCurrentItem(itemCopy);
    }
  }, [stepIsReady]);

  useEffect(() => {
    setCurrentStep(currentItem.selectedSteps[stepIndex]);
  }, [currentItem]);

  return (
    <div className="-mx-1 p-1 py-3 rounded-lg">
      <div className={`flex gap-2 items-center text-sm ${ColorPicker("text", colors.buttons)} font-bold -mb-1`}>
        <span className="underline">{title}</span>
        {options_limit != options_required && (
          <div className="flex gap-2">
            <span>{required ? `Mínimo ${options_required}` : `(Opcional)`}</span>
            {showMinimum && <span>Máximo {options_limit}</span>}
          </div>
        )}
        <span className="">{stepIsReady ? "✅" : ""}</span>
      </div>
      <div className="flex flex-col">
        <StepOptions
          item={item}
          step={step}
          stepIndex={stepIndex}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          optionsSelectedCount={optionsSelectedCount}
          setOptionsSelectedCount={setOptionsSelectedCount}
          currentStep={currentStep}
        />
      </div>
    </div>
  );
};

export default StepPicker;
