// Takes an object of open days like:
// open_days_in_order: [
//   {
//     id: 980190962,
//     day: "lunes",
//     opening_hour: "2000-01-01T08:00:00.000Z",
//     closing_hour: "2000-01-01T20:00:00.000Z",
//     business_id: 980190962,
//     created_at: "2023-09-06T03:39:21.347Z",
//     updated_at: "2023-09-06T03:39:21.347Z",
//   },
//   {
//     id: 298486374,
//     day: "martes",
//     opening_hour: "2000-01-01T08:00:00.000Z",
//     closing_hour: "2000-01-01T20:00:00.000Z",
//     business_id: 980190962,
//     created_at: "2023-09-06T03:39:21.347Z",
//     updated_at: "2023-09-06T03:39:21.347Z",
//   },
//   {
//     id: 113629430,
//     day: "miércoles",
//     opening_hour: "2000-01-01T08:00:00.000Z",
//     closing_hour: "2000-01-01T20:00:00.000Z",
//     business_id: 980190962,
//     created_at: "2023-09-06T03:39:21.347Z",
//     updated_at: "2023-09-06T03:39:21.347Z",
//   },
// ],
// and returns a paragraph with the open days and hours like:
// Lunes: 8:00 AM - 8:00 PM
// Martes: 8:00 AM - 8:00 PM
// etc

const convertHourTo12 = (hour) => {
  let hour12 = "";
  const hour24 = hour.slice(11, 16);
  const hour24Int = parseInt(hour24.slice(0, 2));
  if (hour24Int > 12) {
    hour12 = `${hour24Int - 12}${hour24.slice(2, 5)} PM`;
  } else if (hour24Int === 12) {
    hour12 = `${hour24} PM`;
  } else {
    hour12 = `${hour24} AM`;
  }
  return hour12;
};

const scheduleHours = (day) => {
  const { opening_hour, closing_hour } = day;
  return `${convertHourTo12(opening_hour)} - ${convertHourTo12(closing_hour)}`;
};

const scheduleDay = (day) => {
  const { day: dayName, opening_hour, closing_hour } = day;
  return `${dayName.charAt(0).toUpperCase() + dayName.slice(1)}: ${scheduleHours(day)}`;
};

const liDay = (day) => {
  return <li key={day.id}>{scheduleDay(day)}</li>;
};

const Schedule = (openDays) => {
  return <ul>{openDays.map((day) => liDay(day))}</ul>;
};

const openHoursToday = (openDays) => {
  const weekDays = ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"];

  const currentDay = new Date().getDay();
  const givenDayObject = openDays.find((day) => day.day === weekDays[currentDay]);
  if (!givenDayObject) return false;

  return scheduleHours(givenDayObject);
};

export default Schedule;
export { scheduleDay, openHoursToday };
