import { Card } from "@material-tailwind/react";
import { useSelector } from "react-redux";
import Category from "./Category";
import ColorPicker from "../hooks/ColorPicker";

const Menu = () => {
  const { rest } = useSelector((state) => state.rest);
  const colors = rest.siteColors;
  // categories are those rest.site.menu.categories that are active and have at leaste one item that is active
  const categories = rest.site.menu.categories.filter((category) => {
    const items = category.items.filter((item) => item.active);
    return category.active && items.length > 0;
  });

  const hasActiveCategories = categories.length > 0;

  return (
    <div className="accordion accordion-flush" id="menu">
      {hasActiveCategories ? (
        <>
          <Card className={`w-full ${ColorPicker("text", colors.text)}`}>
            {categories.map((category) => (
              <Category key={category.id} category={category} />
            ))}
          </Card>
        </>
      ) : (
        <div className="flex flex-col gap-2 text-center mt-2">
          <span className="text-4xl">👷‍♀️👷</span>
          <span>
            El menú de {rest.name} <br />
            está en construcción
          </span>
        </div>
      )}
    </div>
  );
};

export default Menu;