import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@material-tailwind/react";
import ExtraIngridient from "./ExtraIngridient";
import g from "../hooks/Genderize";
import RenderItems from "./RenderItems";
import Combo from "./Combo";
import ColorPicker from "../hooks/ColorPicker";

const CombosForItem = ({ item, i, itemsPreview, setItemsPreview }) => {
  const colors = useSelector((state) => state.rest.rest.siteColors);
  const nameAndNumber = `${item.name} Nro ${i + 1}`;
  const [currentCombo, setCurrentCombo] = useState({});
  const [price, setPrice] = useState(item.price);
  const [cleared, setCleared] = useState(true);
  const [completed, setCompleted] = useState(false);
  const [currentItem, setCurrentItem] = useState({ ...item });

  const handleComboPick = (combo) => {
    if (currentCombo !== combo) {
      setCurrentCombo(combo);
      setPrice(item.price + combo.price);
    } else {
      setCurrentCombo({});
      setPrice(item.price);
    }
  };

  const resetAll = () => {
    setCurrentCombo({});
    setPrice(item.price);
    setCleared(true);
  };

  useEffect(() => {
    if (currentCombo.name || price > item.price) {
      setCleared(false);
    } else {
      setCleared(true);
    }
  }, [currentCombo, price]);

  useEffect(() => {
    let itemCopy = { ...item };
    itemCopy.price = price;
    itemCopy = {
      ...itemCopy,
      addedCombo: currentCombo,
      comboed: true,
      customized: true,
    };
    setCurrentItem(itemCopy);
  }, [currentCombo, itemsPreview]);

  const handleNewItemAddition = () => {
    let itemsPreviewCopy = [...itemsPreview];
    itemsPreviewCopy.push(currentItem);
    setItemsPreview(itemsPreviewCopy);
    setCompleted(true);
  };

  const handleBackToEdit = () => {
    let itemsPreviewCopy = [...itemsPreview];
    itemsPreviewCopy.splice(itemsPreviewCopy.indexOf(currentItem), 1);
    setItemsPreview(itemsPreviewCopy);
    setCompleted(false);
  };

  return (
    <div className="flex flex-col gap-3">
      <div className="flex flex-col gap-3">
        {/* What we show when combo is picked */}
        {completed ? (
          <div className="flex flex-col w-full justify-between items-start gap-2">
            <div className="flex justify-between items-center gap-2 w-full">
              <p className={`text-left ${ColorPicker("text", colors.buttons)}`}>
                <strong>{nameAndNumber} </strong>
                <span>{g(item, "listo ✅", "lista ✅", "s")}</span>
              </p>
              <Button
                variant="outlined"
                color={colors.buttons}
                size="sm"
                className="self-end border-dashed"
                onClick={() => handleBackToEdit()}
              >
                <span>Editar</span>
              </Button>
            </div>
            <RenderItems items={currentItem} />
          </div>
        ) : (
          <>
            <div className="text-md font-bold">
              Personaliza {g(item, "el", "la", "s")}{" "}
              <strong className={`${ColorPicker("text", colors.buttons)} underline`}>{nameAndNumber}</strong>:
            </div>
            <div className="-mx-1 p-1 py-3 rounded-lg">
              <p className={`text-sm font-bold -mb-1 ${ColorPicker("text", colors.buttons)}`}>Escoge el combo:</p>
              <div className="flex flex-col">
                {currentCombo &&
                  item.combos.map((combo) => {
                    return (
                      <Combo
                        key={combo.id}
                        combo={combo}
                        handleComboPick={handleComboPick}
                        currentCombo={currentCombo}
                        setPrice={setPrice}
                      />
                    );
                  })}
              </div>
            </div>
            <div className="text-xs">
              <p>
                Resumen de <strong className={`${ColorPicker("text", colors.buttons)}`}>{nameAndNumber}:</strong>
              </p>
              <div>
                {currentCombo.name && (
                  <p>
                    <span>✅ {currentCombo.name}</span>
                  </p>
                )}
              </div>
            </div>
            <p className="text-xl font-bold underline underline-offset-4 text-right px-3">Precio: ${price}</p>
            <div className="flex justify-between w-full items-center gap-2">
              <Button
                variant="outlined"
                color={colors.negative}
                size="sm"
                className={`w-4/12 border-dashed ${cleared ? "grayscale" : null}`}
                onClick={resetAll}
                disabled={cleared}
              >
                Reiniciar
              </Button>
              <Button
                variant="outlined"
                color={colors.buttons}
                size="sm"
                className={`relative w-8/12 border-dashed ${cleared ? "grayscale" : null}`}
                onClick={() => handleNewItemAddition()}
                disabled={cleared}
              >
                {g(item, "Listo", "Listo", "s")}
              </Button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CombosForItem;
