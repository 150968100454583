import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addItem, removeItem } from "../store/cartSlice";
import { IconButton, Button, Typography } from "@material-tailwind/react";
import g from "../hooks/Genderize";
import BuildItem from "./BuildItem";
import RenderItems from "./RenderItems";
import groupItems from "../hooks/GroupItems";
import ColorPicker from "../hooks/ColorPicker";

const BuildForm = ({ item, customizedAdded, setCustomizedAdded, setCustomizedItemsOnCart, modalId }) => {
  const { rest } = useSelector((state) => state.rest);
  const colors = rest.siteColors;
  const dispatch = useDispatch();
  const { steps, price, name } = item;
  // How many items client wants. For these items, the total is always the same total to build
  // because all items should be buildable, otherwise would be just a customized item
  const [itemsTotal, setItemsTotal] = useState(1);
  // const [customizedTotal, setCustomizedTotal] = useState(1);
  const [stepIndex, setStepIndex] = useState(0);
  const [step, setStep] = useState(1);
  const stepsCount = steps.length;
  const [helperArray, setHelperArray] = useState([...Array(1)]);
  // Items preview has all the items, built and normal, before adding them to the cart
  const [itemsPreview, setItemsPreview] = useState([]);
  const [builtTotal, setBuiltTotal] = useState(0);
  const [previewPrice, setPreviewPrice] = useState(price);
  const [reseted, setReseted] = useState(true);

  // Creating helper array to render BuildItem component x times
  useEffect(() => {
    setHelperArray([...Array(itemsTotal)]);
  }, [itemsTotal]);

  // To easily have the actual steps[index]
  useEffect(() => {
    setStepIndex(step - 1);
  }, [step]);

  // count how many are finished, ("built" here?) (NOT ADAPTED YET TO THIS FORM)
  const calculateBuiltTotal = () => {
    let count = 0;
    for (const it of itemsPreview) {
      if (it.built) {
        count += 1;
      }
    }
    return count;
  };

  const calculatePreviewPrice = (items) => {
    let price = 0;
    for (const it of items) {
      price += it.price;
    }
    return price;
  };

  useEffect(() => {
    setBuiltTotal(calculateBuiltTotal());
    setPreviewPrice(calculatePreviewPrice(itemsPreview));
  }, [itemsPreview]);

  const addFormToCart = () => {
    itemsPreview.map((it) => {
      dispatch(addItem(it));
    });
    setCustomizedAdded(true);
    setCustomizedItemsOnCart(itemsPreview);
  };

  const removeFormFromCart = () => {
    itemsPreview.map((it) => {
      dispatch(removeItem(it));
    });
    setCustomizedAdded(false);
  };

  const reset = () => {
    setItemsTotal(1);
    // setCustomizedTotal(1);
    setStep(1);
    setHelperArray([...Array(1)]);
    setItemsPreview([]);
    setBuiltTotal(0);
    setPreviewPrice(price);
  };

  useEffect(() => {
    if (itemsTotal > 1 || step > 1) {
      setReseted(false);
    } else {
      setReseted(true);
    }
  }, [itemsTotal, step]);

  useEffect(() => {
    if (!customizedAdded) {
      reset();
    }
  }, [customizedAdded]);

  // This is the wrapper component.
  // Step 1 = Choose how many
  // Step 2 = Build those "many"
  // Step 3 = Preview and add to cart

  return (
    <div
      className="modal fade fixed top-0 left-0 hidden w-full h-full outline-none py-5 backdrop-blur-sm"
      id={modalId}
      tabIndex="-1"
      aria-labelledby={`${modalId}Label`}
      aria-hidden="true"
    >
      <div className={`modal-dialog modal-dialog-scrollable relative w-auto pointer-events-none`}>
        <div
          className={`modal-content border-none shadow-lg relative flex flex-col w-full pointer-events-auto ${ColorPicker(
            "background",
            colors.card
          )} bg-clip-padding rounded-lg outline-none`}
        >
          {/* modal header */}
          <div className="modal-header flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md shadow-md">
            <div>
              {step === 1 && (
                <p className="text-md">
                  <strong className="font-bold">PASO 1:</strong> <span>Escoge el total.</span>
                </p>
              )}
              {step === 2 && (
                <p className="text-sm pb-1">
                  <strong>PASO 2:</strong>{" "}
                  <span>
                    Arma {g(item, "los", "las", "p")} "{item.name}" que deseas.
                  </span>
                </p>
              )}
              {step === 3 && (
                <p className="text-sm ">
                  <strong>PASO 3:</strong> <span>Confirma la información y añade al pedido.</span>
                </p>
              )}
            </div>
            <Button variant="outlined" size="sm" color={colors.negative} data-bs-dismiss="modal">
              Cancelar
            </Button>
          </div>
          {/* modal body */}
          <div className="modal-body relative p-4 overflow-x-hidden overflow-y-auto">
            {step === 1 && (
              <div
                className={`step-1 flex flex-col gap-3 p-4 border  
                border-dashed
                ${ColorPicker("border", colors.brand)}
                rounded-lg`}
              >
                <div className="step-1-a flex justify-between items-center gap-1">
                  <p className="text-sm">
                    ¿{g(item, "Cuántos", "Cuántas", "p")} "<strong>{name}</strong>" deseas armar en total?
                  </p>
                  <div className="flex flex-col items-rigth">
                    <p className="font-bold italic text-right">En total:</p>
                    <div className="flex gap-1 items-center justify-center">
                      <IconButton
                        variant="outlined"
                        color={itemsTotal === 1 ? "gray" : colors.brand}
                        className={`flex-none w-6 h-6 ${itemsTotal === 1 ? "border-gray-400" : null}`}
                        size="sm"
                        onClick={() => {
                          setItemsTotal(itemsTotal - 1);
                        }}
                        disabled={itemsTotal === 1}
                      >
                        <p className="text-xl">-</p>
                      </IconButton>
                      <p className="w-6 h-7 border rounded-lg font-bold text-center">{itemsTotal}</p>
                      <IconButton
                        variant="outlined"
                        color={colors.brand}
                        className="flex-none w-6 h-6"
                        onClick={() => setItemsTotal(itemsTotal + 1)}
                        size="sm"
                        disabled={false}
                      >
                        <p className="text-xl">+</p>
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {step === 2 && (
              <div
                className="step-2
              flex
              flex-col
              gap-3
              py-5
              px-2
              border  
              border-dashed
              border-gray-400
              rounded-lg"
              >
                <div className="flex flex-col gap-5">
                  {helperArray.map((_, i) => (
                    <div key={`${item.id}${i}`}>
                      <BuildItem
                        key={`${item.id}${i}`}
                        item={item}
                        i={i}
                        itemsPreview={itemsPreview}
                        setItemsPreview={setItemsPreview}
                        setBuiltTotal={setBuiltTotal}
                      />
                      {i < helperArray.length - 1 && <hr className="border-top-2 border-gray-200" />}
                    </div>
                  ))}
                </div>
              </div>
            )}
            {step === 3 && (
              <div
                className="step-3
              flex
              flex-col
              gap-3
              py-5
              px-2
              border 
              border-dashed  
              rounded-lg
              border-gray-400"
              >
                {customizedAdded && (
                  <p className={`text-center ${ColorPicker("text", colors.text)}`}>
                    Tus <strong>"{name}"</strong> se añadieron al pedido.
                  </p>
                )}
                <div>
                  <p className={`pb-1 ${ColorPicker("text", colors.brand)} font-bold`}>Resumen:</p>
                  <RenderItems items={groupItems(itemsPreview)} />
                  <p className="w-full text-right px-2 font-bold underline underline-offset-3">
                    Sub-total: ${previewPrice}
                  </p>
                </div>
              </div>
            )}
          </div>
          {/* modal footer */}
          <div
            className={`modal-footer w-full ${ColorPicker(
              "background",
              colors.card
            )} flex gap-2 items-center justify-end p-4 border-t border-gray-200 rounded-b-md shadow-md`}
          >
            <Button
              variant="gradient"
              size="sm"
              color={reseted ? "gray" : colors.negative}
              onClick={() => reset()}
              className={`border-dashed grow`}
              disabled={reseted}
            >
              Reiniciar
            </Button>
            <div className="flex w-2/3">
              {step === 1 && (
                <Button variant="gradient" color={colors.brand} size="sm" className="grow" onClick={() => setStep(2)}>
                  Continuar
                </Button>
              )}
              {step === 2 && (
                <Button
                  variant="gradient"
                  color={colors.brand}
                  size="sm"
                  className="grow"
                  onClick={() => setStep(3)}
                  disabled={builtTotal != itemsTotal}
                >
                  Continuar
                </Button>
              )}
              {step === 3 && (
                <Button
                  variant="gradient"
                  color={colors.brand}
                  size="sm"
                  className="relative w-full flex-1/2"
                  onClick={addFormToCart}
                  data-bs-dismiss="modal"
                >
                  Añadir al pedido
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildForm;
