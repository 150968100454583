import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Checkbox } from "@material-tailwind/react";
import StepOptionMultipleButton from "./StepOptionMultipleButton";
import c from "../hooks/Capitalize";
import { CLOSING } from "ws";

// a single option to be picked

const StepOption = ({
  option,
  handleOptionPick,
  handleRepeatableOptionPick,
  currentItem,
  originalStep,
  currentStep,
  optionsSelectedCount,
}) => {
  const colors = useSelector((state) => state.rest.rest.siteColors);
  // checked works for radio buttons to be checked
  const [added, setAdded] = useState(false);

  // checks if this option is already on the item or not
  useEffect(() => {
    if (currentStep && currentStep.currentOptions.findIndex((op) => op.name === option.name) > -1) {
      setAdded(true);
    } else {
      setAdded(false);
    }
  }, [currentItem]);

  return (
    <div className="">
      <div className="flex justify-between items-center -mb-3">
        <div className="flex gap-2 text-sm">
          <p className="">{c(option.name)}</p>
          {/* Show prices in options if buildable doesn't have fixed prices */}
          {!currentItem.buildable_fixed_price && `($${option.price})`}
        </div>
        <div>
          {/* If option cannot be repeated (max_use === 1) use check box*/}
          {option.max_use === 1 ? (
            <Checkbox
              color={colors.buttons}
              size="sm"
              onChange={() => handleOptionPick(option)}
              disabled={false}
              checked={added}
              className="bg-white w-4 h-4"
            />
          ) : (
            <StepOptionMultipleButton
              option={option}
              handleRepeatableOptionPick={handleRepeatableOptionPick}
              originalStep={originalStep}
              optionsSelectedCount={optionsSelectedCount}
              currentItem={currentItem}
              added={added}
              currentStep={currentStep}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StepOption;
