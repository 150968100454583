import siteExampleImg from "./images/site_example.jpg";
import categoryExampleImg from "./images/category_example.jpeg";
import cartExample from "./images/cart_example.jpeg";
import disabledItemExample from "./images/disabled_item_example.jpeg";
import pidamosGoLogo from "./images/pidamosgo_logo.png";
import Fader from "./components/Fader";
import SvgIcon from "../hooks/SvgIcon";
import { useState } from "react";
import HomeNavbar from "./components/HomeNavbar";
import { Button } from "@material-tailwind/react";
// import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";

export default () => {
  const [showNavbar, setShowNavbar] = useState(false);

  // ReactGA.pageview("/home");

  return (
    <>
      <div className="app-wraper min-h-screen max-w-[400px] bg-white font-roboto p-4 relative overflow-hidden">
        {/* Navbar */}
        {showNavbar && <HomeNavbar setShowNavbar={setShowNavbar} />}
        {/* Headline */}
        <div className="flex justify-between items-center mb-6">
          <img src={pidamosGoLogo} alt="PidamosGO Logo" className="w-[210px]" />
          <div className="text-blue-700">
            {/* burger icon */}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              stroke="currentColor"
              strokeWidth={2}
              onClick={() => setShowNavbar(true)}
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
            </svg>
          </div>
        </div>

        <div className="flex flex-col gap-12">
          {/* parrafo 1 */}
          <div className="flex flex-col gap-2 text-center relative">
            {/* SVG 1 */}
            <div className="text-blue-300 absolute z-0 -right-24 opacity-10">
              <svg width="340" height="150" viewBox="0 0 340 524" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M313.793 495.37C213.748 553.131 85.8199 518.853 28.0587 418.808C-29.7026 318.762 4.57552 190.835 104.621 133.073L285.769 28.4874C385.815 -29.2739 513.742 5.00423 571.504 105.05C629.265 205.095 594.987 333.023 494.941 390.784L313.793 495.37Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div className="z-10">
              <div>
                <h2 className="text-3xl font-bold">Crea un menú digital</h2>
                <p className="text-lg font-bold">¡En minutos!</p>
              </div>
              <p>
                Te brindamos una plataforma sencilla con la cual, sin necesidad de conocimientos técnicos ni ayuda de
                terceros, puedes crear, administrar y mantener actualizado tu menú digital.
              </p>
            </div>
          </div>

          {/* imagen 1  */}
          <div className="flex flex-col gap-2 text-center items-center">
            <div className="relative w-[280px] h-[320px] overflow-hidden text-center block">
              <img src={siteExampleImg} alt="Página ejemplo" className="site-example-img" />
              <Fader />
            </div>
          </div>

          {/* parrafo 2 */}
          <div className="flex flex-col gap-2 text-center">
            <div>
              <p className="text-md font-bold text-sky-500">Rápido y fácil</p>
              <h2 className="text-3xl font-bold ">Crea categorías y artículos</h2>
            </div>
            <p>
              Tu menú puede contar con una serie de categorías y artículos que puedes ordenar según tus necesidades.
            </p>
          </div>

          {/* imagen 2 */}
          <div className="flex flex-col gap-2 text-center items-center">
            <div className="relative w-[280px] h-[320px] overflow-hidden text-center block">
              <img src={categoryExampleImg} alt="Página ejemplo" className="site-example-img" />
              <Fader />
            </div>
          </div>

          {/* parrafo 3 */}
          <div className="flex flex-col gap-2 text-center">
            <div>
              <p className="text-md font-bold text-sky-500">Agiliza tus pedidos</p>
              <h2 className="text-3xl font-bold ">Delivery y en el sitio</h2>
            </div>
            <p>Con tu menú digital, permite a tus clientes armar sus pedidos y enviártelos por WhatsApp en segundos.</p>
          </div>

          {/* imagen 3 */}
          <div className="flex flex-col gap-2 text-center items-center">
            <div className="relative w-[280px] h-[220px] overflow-hidden text-center block">
              <img src={cartExample} alt="Página ejemplo" className="site-example-img" />
              <Fader />
            </div>
          </div>

          {/* parrafo 4 */}
          <div className="flex flex-col gap-2 text-center">
            <div className="text-blue-300 absolute z-0 -left-24 opacity-10">
              <svg width="343" height="523" viewBox="0 0 343 523" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M56.793 494.941C-43.2524 552.703 -171.18 518.424 -228.941 418.379C-286.703 318.334 -252.424 190.406 -152.379 132.645L28.7693 28.0587C128.815 -29.7026 256.742 4.57552 314.504 104.621C372.265 204.666 337.987 332.594 237.941 390.355L56.793 494.941Z"
                  fill="currentColor"
                />
              </svg>
            </div>
            <div>
              <p className="text-md font-bold text-sky-500">Una dirección sencilla</p>
              <h2 className="text-3xl font-bold ">Escoge la dirección de tu página</h2>
            </div>
            <p>
              Puedes escoger la dirección de tu página y compartirla con tus clientes para que accedan fácilmente a tus
              servicios. Algo como:
            </p>
            <p className="font-bold text-blue-700">pidamosgo.com/tunegocio</p>
          </div>

          {/* parrafo 5 */}
          <div className="flex flex-col gap-2 text-center">
            <div>
              <p className="text-md font-bold text-sky-500">Actualiza en tiempo real</p>
              <h2 className="text-3xl font-bold ">¿Se agotó un artículo?</h2>
            </div>
            <p>
              Puedes actualizar el estado de tu negocio y de tus artículos en tiempo real desde tu panel de control. ¡No
              necesitas ayuda de terceros!
            </p>
          </div>

          {/* imagen 4 */}
          <div className="flex flex-col gap-2 text-center items-center">
            <div className="relative w-[280px] h-[140px] overflow-hidden text-center block">
              <img src={disabledItemExample} alt="Página ejemplo" className="site-example-img" />
              <Fader height="20px" />
            </div>
          </div>

          {/* parrafo 6 */}
          <div className="flex flex-col gap-4 text-center rounded-lg shadow-md border border-gray-200 p-4 bg-white z-20">
            <h2 className="text-3xl font-bold  text-blue-700">¡EMPIEZA AHORA!</h2>
            <p>
              Crea tu usuario, crea la cuenta de tu negocio, arma tu menú en pocos minutos y compártelo con tus clientes.
            </p>
            <div className="text-xs italic">
              <p> Nuestra plataforma es <strong>gratuita</strong>.</p>
              <p>No hace falta registrar ningún método de pago.</p>
            </div>
            <a href={`https://app.pidamosgo.com`} target="_blank" className="w-full">
              <Button
                variant="gradient"
                size="sm"
                fullWidth
                className="relative flex justify-center items-center text-center"
                color="blue"
              >
                <span>CREAR MENÚ GRATIS</span>
              </Button>
            </a>
          </div>

          {/* parrafo 7 */}
          <div className="flex flex-col gap-2 text-center mb-8">
            <div>
              <p className="text-md font-bold text-sky-500">Directorio de negocios</p>
              <h2 className="text-3xl font-bold ">¿Quiéres ver ejemplos?</h2>
            </div>
            <p className="flex flex-col gap-2">
              Actualmente nuestro directorio está en construcción, pero puedes ver este ejemplo que armamos ¡en menos de
              10 minutos!
              <a
                href="https://www.pidamosgo.com/ejemplo"
                target="_blank"
                className="text-blue-700 font-bold flex gap-2 justify-center items-center w-full"
              >
                {" "}
                <span className="text-xl">👉</span> pidamosgo.com/ejemplo
              </a>
              <span className="text-xs italic">
                Muy pronto publicaremos y promocionaremos los negocios disponibles por categorías y ubicación.
              </span>
            </p>
            <p>
              Visita nuestra página de{" "}
              <a href="https://guias.pidamosgo.com" target="_blank" className="text-blue-700 font-bold">
                guías y tutoriales
              </a>{" "}
              para más información
            </p>
          </div>
        </div>
      </div>
    </>
  );
};
